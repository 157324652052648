import React, { useEffect, useState } from "react";
import s from "./Analytics.module.css";
import { useTranslation } from "react-i18next";
import { CurrencyDynamic } from "./CurrencyDynamic/CurrencyDynamic";
import { SubInformation } from "./SubInformation/SubInformation";
import Transaction from "../../assets/Transactions.svg";
import Income from "../../assets/income.svg";
import Bitcoin from "../../assets/bitcoin.svg";
import Cone from "../../assets/CONE.svg";
import { ChartWithFilters } from "./ChartWithFilters/ChartWithFilters";
import { OperationItem } from "./OperationItem/OperationItem";

const mockNews = [
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
  {
    title: "Покупка Cone",
    date: "12.02.2023",
    value: 54,
    total: 57.564,
    currency: "CONE",
  },
];

export const Analytics = ({ openMenu }) => {
  const { t } = useTranslation();
  return (
    <div className={`${s.wrapper} ${openMenu ? s.wrapperFull : null}`}>
      <h2>{t("analytics.title")}</h2>
      <div className={s.content}>
        <div className={s.infoContent}>
          <div className={s.card}>
            <div className={s.cardContent}>
              <div className={s.sections}>
                <div className={`${s.section} ${s.separator}`}>
                  <div className={s.sectionContent}>
                    <CurrencyDynamic
                      title={t("analytics.currentBalance")}
                      amount={86456.88}
                      percent={3.67}
                    />
                  </div>
                </div>
                <div className={`${s.section}`}>
                  <SubInformation
                    imageSource={Transaction}
                    title={t("analytics.transaction")}
                    amount={664546.88}
                  />
                  <SubInformation
                    imageSource={Income}
                    title={t("analytics.income")}
                    amount={664546.88}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={s.card}>
            <div className={s.cardContent}>
              <div className={s.cardTitle}>{t("analytics.assets")}</div>
              <div className={s.sections}>
                <div className={`${s.section} ${s.separator}`}>
                  <div className={s.sectionContent}>
                    <CurrencyDynamic
                      image={Bitcoin}
                      title={"Bitcoin"}
                      amount={86456.88}
                      percent={3.67}
                    />
                  </div>
                </div>
                <div className={`${s.section}`}>
                  <CurrencyDynamic
                    padding={40}
                    image={Cone}
                    title={"CONE"}
                    amount={86456.88}
                    percent={3.67}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={s.card}>
            <div className={s.cardContent}>
              <ChartWithFilters />
            </div>
          </div>
        </div>
        <div className={s.infoList}>
          <div className={s.card}>
            <div className={s.cardContent}>
              <div className={s.cardTitle}>{t("analytics.lastOperations")}</div>
              <div className={s.list}>
                {mockNews.length
                  ? mockNews.map((el, ind) => (
                      <OperationItem key={ind + el.date} item={el} />
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
