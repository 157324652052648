import { useEffect, useRef, useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import s from "./WalletSelector.module.css";
import { UseClickOutside } from "../../../../../../../hooks/UseClickOutside";
import { isMobile, reduceString } from "../../../../../../../utils/helpers";

export const WalletSelector = ({
  items,
  onChange,
  selectedItem,
  zIndex = "",
  boldName = false,
  fullName = false,
  disabled = false,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [size, setSize] = useState(false);
  const selectWrapRef = useRef(null);
  UseClickOutside(
    selectWrapRef,
    () => {
      setIsOpened(false);
    },
    isOpened
  );

  useEffect(() => {
    const listenResizeEvent = () => {
      window.innerWidth <= 435 ? setSize(true) : setSize(false);
    };
    listenResizeEvent();
    window.addEventListener("resize", listenResizeEvent, { passive: true });
    return () => window.removeEventListener("resize", listenResizeEvent);
  }, []);

  return (
    <div
      ref={selectWrapRef}
      data-noclose
      className={disabled ? `${s.wrapper} ${s.disabled}` : `${s.wrapper}`}
    >
      <div
        data-noclose
        onClick={!disabled ? () => setIsOpened(!isOpened) : null}
        className={s.selectedItem}
      >
        <div
          className={s.textWrap}
          data-noclose
          style={boldName ? { fontWeight: "bold" } : {}}
        >
          {size ? reduceString(selectedItem?.name) : selectedItem?.name}

          {fullName ? (
            <div data-noclose className={s.fullName}>
              {selectedItem?.fullName}
            </div>
          ) : null}
        </div>

        <MdOutlineArrowBackIosNew
          data-noclose
          style={
            isOpened
              ? {
                  marginLeft: "10px",
                  transform: "rotate(90deg)",
                  color: "#BEBDBD",
                }
              : {
                  marginLeft: "10px",
                  transform: "rotate(270deg)",
                  color: "#BEBDBD",
                }
          }
        />
      </div>
      <div
        data-noclose
        style={isOpened ? { zIndex } : { zIndex, display: "none" }}
        className={s.dropdown}
      >
        {items?.length
          ? items.map((el, ind) => {
              return (
                <div
                  data-noclose
                  key={ind + el.value}
                  onClick={
                    !disabled
                      ? () => {
                          onChange(el);
                          setIsOpened(!isOpened);
                        }
                      : null
                  }
                  className={s.dropdownItem}
                >
                  <div data-noclose className={s.itemName}>
                    {size ? reduceString(el.name) : el.name}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};
