import eth from "../assets/currencies/eth.png";
import bnb from "../assets/currencies/bnb.png";
import btc from "../assets/currencies/bitcoin.png";
import usdt from "../assets/currencies/tether.png";
import busd from "../assets/currencies/banance-usd.png";
import MetamaskImg from "../assets/metamask.png";
import TrustWalletImg from "../assets/TWT.png";

export const erc20contracts = {
  dev: "0x6324338EC709157AE222E2E37eC8A96EE77E3603",
  prod: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
};

export const walletConnectRpc = {
  dev: "https://ethereum-goerli.publicnode.com",
  prod: "https://eth.llamarpc.com",
};

export const currencies = {
  usdt: "USDT-ERC20",
  eth: "ETH",
};

export const availableCurrencies = [
  {
    name: "ETH",
    network: null,
    value: "ETH",
    icon: eth,
  },
  {
    name: "BNB",
    network: null,
    value: "BNB",
    icon: bnb,
  },
  {
    name: "BTC",
    network: null,
    value: "BTC",
    icon: btc,
  },
  {
    name: "USDT",
    network: "ERC20",
    value: "USDT-ERC20",
    icon: usdt,
  },
  {
    name: "USDT",
    network: "TRX20",
    value: "USDT-TRX20",
    icon: usdt,
  },
];

export const walletSystems = [
  {
    name: "Metamask",
    value: "metamask",
    icon: MetamaskImg,
  },
  {
    name: "Wallet Connect ",
    value: "walletConnect",
    icon: TrustWalletImg,
  },
];

export const walletModalDefaultStyles = {
  themeVariables: {
    "--wcm-z-index": 50000,
    "--wcm-overlay-background-color": "transparent",
  },
};

export const walletDefaultMethods = [
  "eth_sendTransaction",
  "eth_signTransaction",
  "eth_sign",
  "personal_sign",
  "eth_signTypedData",
];
