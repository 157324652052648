import { useTranslation } from "react-i18next";
import { getValidateStyles } from "../../utils/helpers";
import s from "./PasswordValidator.module.css";

const PasswordValidator = ({ formik, passwordValidate, resize }) => {
  const { t } = useTranslation();

  return resize ? (
    <div className={s.passValidateWrapper}>
      <span style={getValidateStyles(0, formik, passwordValidate)}>
        {t("errors.passContain")}
      </span>
      <ul>
      <li style={getValidateStyles(6, formik, passwordValidate)}>
          {t("errors.latin")}
        </li>
        <li style={getValidateStyles(5, formik, passwordValidate)}>
          {t("errors.minLength")}
        </li>
        <li style={getValidateStyles(1, formik, passwordValidate)}>
          {t("errors.num")}
        </li>
        <li style={getValidateStyles(2, formik, passwordValidate)}>
          {t("errors.lett")}
        </li>
        <li style={getValidateStyles(3, formik, passwordValidate)}>
          {t("errors.uppLett")}
        </li>
        <li style={getValidateStyles(4, formik, passwordValidate)}>
          {t("errors.specSymb")}
        </li>
      </ul>
    </div>
  ) : (
    <div className={s.passValidateWrapper} style={{ marginTop: "6px" }}>
      <span style={getValidateStyles(0, formik, passwordValidate)}>
        {t("errors.passContain")}
      </span>
      <ul>
      <li style={getValidateStyles(6, formik, passwordValidate)}>
          {t("errors.latin")}
        </li>
        <li style={getValidateStyles(5, formik, passwordValidate)}>
          {t("errors.minLength")}
        </li>
        <li style={getValidateStyles(1, formik, passwordValidate)}>
          {t("errors.num")}
        </li>
        <li style={getValidateStyles(2, formik, passwordValidate)}>
          {t("errors.lett")}
        </li>
        <li style={getValidateStyles(3, formik, passwordValidate)}>
          {t("errors.uppLett")}
        </li>
        <li style={getValidateStyles(4, formik, passwordValidate)}>
          {t("errors.specSymb")}
        </li>
      </ul>
    </div>
  );
};

export default PasswordValidator;
