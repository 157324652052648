import { ethers } from "ethers";
import { ERC20Abi } from "./abis/erc20Abi";
import {
  currencies,
  erc20contracts,
  walletConnectRpc,
  walletDefaultMethods,
  walletModalDefaultStyles,
} from "./constanst";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import { GET_REPLENISHMENTS_HISTORY } from "../redux/sagas/historySaga";

export const reduceString = (value) => {
  if (value?.length < 11) {
    return value;
  }
  if (!value) {
    return "";
  }
  return `${value?.substring(0, 8)}...${value?.substring(value.length - 8)}`;
};

export const getAuth = () => {
  let preAuth = document.cookie?.split("BIT/")[1];
  if (preAuth) {
    return {
      token: preAuth?.split("=")[1],
      type: preAuth?.split("=")[0],
    };
  } else {
    return null;
  }
};

export const isMobile = () => {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);
  if (isMobileDevice) {
    return true;
  } else {
    return false;
  }
};

export const copyToClipboard = (e, t, alert, toWrite) => {
  e.preventDefault();
  navigator.clipboard.writeText(toWrite);
  alert("success", t("settings.copied"));
};

export const getStatusTitle = (status, t) => {
  switch (status) {
    case "CREATED":
      return t("statusesTitle.createdTitle");
    case "RECEIVING":
      return t("statusesTitle.receivingTitle");
    case "PAYMENT":
      return t("statusesTitle.paymentTitle");
    case "EXPIRED":
      return t("statusesTitle.expiredTitle");
    case "DONE":
      return t("statusesTitle.doneTitle");
    case "ERROR":
      return "";
    default:
      return "";
  }
};

export const getColorStatus = (status) => {
  switch (status) {
    case "DONE":
      return "#1fbd1f";
    case "EXPIRED":
      return "#ff0a0a";
    case "RECEIVED":
      return "#ffa818";
    default:
      return "#ffa818";
  }
};

export const handleHistoryFetch = (dispatch, location, history, alert, t) => {
  if (location.page) {
    dispatch({
      type: GET_REPLENISHMENTS_HISTORY,
      page: location.page,
      alert,
      t,
    });
  } else {
    dispatch({
      type: GET_REPLENISHMENTS_HISTORY,
      page: 1,
      history,
      alert,
      t,
    });
  }
};

export const getValidateStyles = (
  el,
  formik = null,
  passwordValidate = false
) => {
  if (el === 0) {
    if (
      formik?.values.password?.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/
      )
    ) {
      return { color: "#2ac769" };
    } else {
      return { color: "#323232" };
    }
  } else if (el === 1) {
    if (formik?.values.password?.match(/(?=.*\d)/)) {
      return { color: "#2ac769" };
    }
  } else if (el === 2) {
    if (formik?.values.password?.match(/(?=.*[a-z])/)) {
      return { color: "#2ac769" };
    }
  } else if (el === 3) {
    if (formik?.values.password?.match(/(?=.*[A-Z])/)) {
      return { color: "#2ac769" };
    }
  } else if (el === 4) {
    if (
      formik?.values.password?.match(/^(?=.*[@$!%*?&#^])[^_.+=/()[{}|:;~]+$/)
    ) {
      return { color: "#2ac769" };
    }
  } else if (el === 5) {
    if (formik?.values.password?.length > 7) {
      return { color: "#2ac769" };
    }
  } else if (el === 6) {
    if (!formik?.values.password?.match(/[^\u0000-\u007F]+/g) && formik?.values.password?.length > 0) {
      return { color: "#2ac769" };
    }
  } else if (el === "i") {
    if (
      !passwordValidate &&
      formik?.values.password !== formik?.values.confirm_password &&
      formik?.values.confirm_password.length
    ) {
      return { border: "1px solid #ff6464" };
    } else if (
      passwordValidate &&
      formik?.values.password?.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/
      )
    ) {
      return { border: "1px solid #2ac769" };
    } else if (passwordValidate && !formik?.values.password) {
      return { border: "1px solid #2ac769" };
    } else if (passwordValidate) {
      return { border: "1px solid #ff6464" };
    }
  }
};

export const closeModalWalletConnect = () => {
  const html = document.querySelector("html");
  html.style.remove();
  html.style.remove();
  const items = document.getElementsByTagName("wcm-modal");
  for (let item of items) {
    item.remove();
  }
};

export const getChainId = () => {
  if (process.env.REACT_APP_ENV === "dev") {
    return 5;
  } else {
    return 1;
  }
};

export const clearAfterWalletConnect = () => {
  const translation = localStorage.getItem("i18nextLng");
  const email = localStorage.getItem("e");
  localStorage.clear();
  localStorage.setItem("i18nextLng", translation);
  localStorage.setItem("e", email);
};

export const createTransactionMetamask = async (
  replenishment,
  setStep,
  alert,
  t
) => {
  const chainId = getChainId("metamask");
  if (!window.ethereum) {
    alert("error", t("installWallet"));
    throw new Error("No crypto wallet found. Please install it.");
  }
  await window.ethereum.send("eth_requestAccounts");
  if (window.ethereum.networkVersion !== chainId) {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${chainId}` }],
    });
  }
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  await sendTransaction(signer, replenishment, setStep, alert, t);
};

export const createTransactionWalletConnect = async (
  replenishment,
  close,
  alert,
  t
) => {
  clearAfterWalletConnect();
  const chain = getChainId();
  const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID;
  const provider = await EthereumProvider.init({
    projectId,
    chains: [chain],
    showQrModal: true,
    methods: walletDefaultMethods,
    events: [],
    rpcMap: {
      [chain]: walletConnectRpc[process.env.REACT_APP_ENV],
    },
    qrModalOptions: walletModalDefaultStyles,
  });
  try {
    provider.modal.subscribeModal((e) => {
      if (!e.open) {
        provider.modal.closeModal();
        return;
      }
    });
    await provider.connect();
    const web3Provider = new ethers.providers.Web3Provider(provider);
    const signer = await web3Provider.getSigner();
    provider.once("chainChanged", async (e) => {
      if (e !== `0x${chain}` && replenishment.currency !== currencies.usdt) {
        alert("error", t("buyConePopup.changeNetwork"));
        provider.closeModal();
        await provider.disconnect();
        clearAfterWalletConnect();
        throw new Error("Wrong newtwork");
      }
    });
    await sendTransaction(signer, replenishment, close, alert, t);
    clearAfterWalletConnect();
  } catch (e) {
    provider.modal.closeModal();
    await provider.disconnect();
    clearAfterWalletConnect();
    alert("error", t("buyConePopup.declined"));
  }
};

const sendTransaction = async (signer, replenishment, close, alert, t) => {
  const chainId = getChainId();
  try {
    if (replenishment.currency === currencies.usdt) {
      const contract = new ethers.Contract(
        erc20contracts[process.env.REACT_APP_ENV],
        ERC20Abi,
        signer
      );
      const tx = await contract.transfer(
        replenishment.replenishment_address,
        ethers.utils.parseUnits(replenishment.replenishment_amount, 8),
        {
          gasLimit: ethers.utils.hexlify(1000000),
        }
      );
      close();
      alert("success", t("buyConePopup.transactionSuccess"));
      return tx;
    } else {
      const tx = await signer.sendTransaction({
        to: replenishment.replenishment_address,
        value: ethers.utils.parseEther(replenishment.replenishment_amount),
        chainId: `0x${chainId}`,
        gasLimit: ethers.utils.hexlify(1000000),
      });
      close();
      alert("success", t("buyConePopup.transactionSuccess"));
      return tx;
    }
  } catch (e) {
    alert("error", t("buyConePopup.declined"));
    throw new Error("Declined");
  }
};
