import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCaretUp } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";
import { VscChromeClose } from "react-icons/vsc";
import { AlertContext } from "../../App";
import { QRCodeSVG } from "qrcode.react";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import s from "./Settings.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_QR_URL,
  REMOVE_TWO_FA,
  SET_BACKEND_LANG,
  UPDATE_PASSWORD,
  VERIFY_TWO_FA,
} from "../../redux/sagas/settingsSaga";
import {
  GET_SETTING_VALUES,
  setErrors,
} from "../../redux/reducers/settingsReducer";
import { IconWrapper } from "../../common-components/IconWrapper/IconWrapper";

const Settings = ({ openMenu }) => {
  const { t, i18n } = useTranslation();

  const currentLang = localStorage.getItem("i18nextLng");
  const dispatch = useDispatch();
  const twoFactAuth = useSelector((state) => state.settings.twoFactorVerify);
  const errors = useSelector((state) => state.settings.errors);
  const alert = useContext(AlertContext);

  const [authSwitch, setAuthSwitch] = useState(null);
  const [openLang, setOpenLang] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [authStep, setAuthStep] = useState(1);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [type, setType] = useState({ pass: "password", repeat: "password" });
  const [passwordChange, setPasswordChange] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [lang, setLang] = useState(currentLang);
  const [passwordValidate, setPasswordValidate] = useState(false);
  const [resizeSmall, setResizeSmall] = useState(true);
  const [resizeBig, setResizeBig] = useState(false);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLang(lang);
    setOpenLang(!openLang);
    dispatch({ type: SET_BACKEND_LANG, lang, alert, t });
  };

  useEffect(() => {
    const listenResizeEvent = () => {
      window.innerWidth <= 700 ? setResizeSmall(true) : setResizeSmall(false);
      window.innerWidth > 700 ? setResizeBig(true) : setResizeBig(false);
    };
    listenResizeEvent();
    window.addEventListener("resize", listenResizeEvent, { passive: true });
    return () => window.removeEventListener("resize", listenResizeEvent);
  }, []);

  useEffect(() => {
    if (twoFactAuth === "true") {
      setAuthSwitch(true);
    } else {
      setAuthSwitch(false);
    }
    setAuthStep(1);
  }, [twoFactAuth]);

  useEffect(() => {
    if (openChangePassword === false) {
      setPasswordChange({
        oldPassword: "",
        password: "",
        confirmPassword: "",
      });
      setPasswordValidate(false);
    }
  }, [openChangePassword]);

  useEffect(() => {
    if (authSwitch !== null) {
      dispatch({ type: GET_SETTING_VALUES, alert, t });
    }
  }, [authSwitch]);

  useEffect(() => {
    errors?.forEach((el) => {
      if (el.message === "Code is incorrect") {
        alert("error", t("settings.incorectCode"));
        dispatch(setErrors(null));
      }
    });
  }, [errors]);

  const verify2FaVerification = () => {
    dispatch({
      type: VERIFY_TWO_FA,
      authCode,
      setAuthSwitch,
      setOpenAuth,
      alert,
      t,
    });
  };

  const remove2FaVerification = () => {
    dispatch({
      type: REMOVE_TWO_FA,
      authCode,
      setAuthSwitch,
      setOpenAuth,
      alert,
      t,
    });
  };

  const changePassword = () => {
    if (
      passwordChange.oldPassword !== passwordChange.password &&
      !passwordChange.password?.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/
      )
    ) {
      setPasswordValidate(true);
    } else if (passwordChange.oldPassword !== passwordChange.password) {
      dispatch({
        type: UPDATE_PASSWORD,
        confirmPassword: passwordChange.confirmPassword,
        oldPassword: passwordChange.oldPassword,
        password: passwordChange.password,
        alert,
        t,
        setOpenChangePassword,
      });
    } else if (passwordChange.oldPassword === passwordChange.password) {
      alert("error", t("usedPass"));
    }
  };

  const getValidateStyles = (el) => {
    if (el === 0) {
      if (
        passwordChange.password?.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/
        )
      ) {
        return { color: "#2ac769" };
      } else {
        return { color: "#323232" };
      }
    } else if (el === 1) {
      if (passwordChange.password?.match(/(?=.*\d)/)) {
        return { color: "#2ac769" };
      }
    } else if (el === 2) {
      if (passwordChange.password?.match(/(?=.*[a-z])/)) {
        return { color: "#2ac769" };
      }
    } else if (el === 3) {
      if (passwordChange.password?.match(/(?=.*[A-Z])/)) {
        return { color: "#2ac769" };
      }
    } else if (el === 4) {
      if (
        passwordChange.password?.match(/^(?=.*[@$!%*?&#^])[^_.+=/()[{}|:;~]+$/)
      ) {
        return { color: "#2ac769" };
      }
    } else if (el === 5) {
      if (passwordChange.password?.length > 7) {
        return { color: "#2ac769" };
      }
    } else if (el === 6) {
      if (!passwordChange.password?.match(/[^\u0000-\u007F]+/g) && passwordChange.password?.length > 0) {
        return { color: "#2ac769" };
      }
    } else if (el === "i") {
      if (
        !passwordValidate &&
        passwordChange.password !== passwordChange.confirmPassword &&
        passwordChange.confirmPassword.length
      ) {
        return { border: "1px solid #ff6464" };
      } else if (
        passwordValidate &&
        passwordChange.password?.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/
        )
      ) {
        return { border: "1px solid #2ac769" };
      } else if (passwordValidate && !passwordChange.password) {
        return { border: "1px solid #2ac769" };
      } else if (passwordValidate) {
        return { border: "1px solid #ff6464" };
      }
    }
  };

  return (
    <div className={`${s.wrapper} ${openMenu ? s.wrapperFull : null}`}>
      <div
        onClick={() => {
          setOpenLang(false);
        }}
        className={s.content}
      >
        <h2>{t("settings.title")}</h2>
        <div className={s.lang}>
          <span className={s.langLabel}>{t("settings.lang")}</span>
          <div className={s.select}>
            <div>
              <span>{lang.toUpperCase()}</span>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setOpenLang(!openLang);
              }}
            >
              {openLang ? <FaCaretUp /> : <FaCaretDown />}
            </div>
          </div>

          {openLang ? (
            <div
              className={s.dropDown}
              onClick={(e) => {
                e.stopPropagation();
                changeLanguage(lang === "ru" ? "en" : "ru");
              }}
            >
              <span>{lang === "ru" ? "EN" : "RU"}</span>
            </div>
          ) : null}
        </div>

        <div className={s.auth}>
          <div className={s.authBlock}>
            <label className={s.switch}>
              <input
                type="checkbox"
                onChange={() => {
                  if (!authSwitch && twoFactAuth === "false") {
                    setOpenAuth(true);
                    setAuthCode("");
                    dispatch({ type: GET_QR_URL, cb: setQrCodeUrl, alert, t });
                  } else if (twoFactAuth === "true") {
                    setAuthStep(2);
                    setAuthCode("");
                    setOpenAuth(true);
                  }
                }}
                checked={twoFactAuth === "true"}
              />
              <span className={s.slider + " " + s.round}></span>
            </label>
          </div>
          <span className={s.authTitle}>{t("settings.authTitle")}</span>
        </div>

        {openAuth ? (
          <div
            className={s.twoFactAuthWrapper}
            onClick={(e) => {
              if (
                e.target.nodeName === "DIV" &&
                !e.target.attributes.hasOwnProperty("data-noclose")
              ) {
                setOpenAuth(false);
                setQrCodeUrl("");
                setAuthStep(1);
              }
            }}
          >
            <div className={s.twoFactAuthContent} data-noclose>
              <div
                onClick={() => {
                  setOpenAuth(false);
                  setAuthCode("");
                  setAuthStep(1);
                }}
                className={s.closeBtn}
              >
                <VscChromeClose />
              </div>
              <span data-noclose className={s.title}>
                {t("settings.authTitle")}
              </span>
              {authStep === 1 ? (
                <>
                  <div data-noclose className={s.authContent}>
                    <QRCodeSVG
                      data-noclose
                      value={qrCodeUrl}
                      width="141px"
                      height="141px"
                    />
                  </div>
                  <span className={s.textAuthContent}>
                    {t("settings.qrInfo")}
                  </span>
                  {resizeSmall ? (
                    <span
                      onClick={() => {
                        navigator.clipboard.writeText(
                          qrCodeUrl.split("secret=")[1]
                        );
                        alert("success", t("copyCod"));
                      }}
                      className={s.copyLink}
                    >
                      {t("settings.copyCode")}
                    </span>
                  ) : null}
                  <div data-noclose className={s.footer}>
                    <button
                      onClick={() => {
                        setOpenAuth(false);
                        setAuthCode("");
                        setAuthStep(1);
                      }}
                      className={s.twoFaBtn}
                    >
                      {t("main.confirmDelete.cancel")}
                    </button>
                    <button
                      data-noclose
                      onClick={() => {
                        setAuthStep(2);
                      }}
                      className={s.twoFaBtn}
                    >
                      {t("main.confirmDelete.next")}
                    </button>
                  </div>
                </>
              ) : authStep === 2 ? (
                <div data-noclose className={s.authContent}>
                  <input
                    data-noclose
                    value={authCode}
                    onChange={(e) => {
                      if (e.target.value.length <= 6)
                        setAuthCode(e.target.value);
                    }}
                    type="text"
                    placeholder={t("settings.codePlaceholder")}
                    style={{ maxWidth: "200px", margin: "auto" }}
                  />
                  <div data-noclose className={s.footer}>
                    <button
                      onClick={() => {
                        setOpenAuth(false);
                        setAuthCode("");
                        setAuthStep(1);
                      }}
                      className={s.authBtn}
                    >
                      {t("main.confirmDelete.cancel")}
                    </button>
                    <button
                      data-noclose
                      disabled={authCode.length < 6}
                      onClick={() => {
                        if (authSwitch) {
                          remove2FaVerification();
                        } else if (authCode?.length < 6) {
                          alert("error", t("settings.incorectCode"));
                        } else if (!authSwitch && authCode) {
                          verify2FaVerification();
                        }
                      }}
                      className={s.authBtn}
                    >
                      {t("main.confirmDelete.next")}
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        {openChangePassword ? (
          <div
            className={s.twoFactAuthWrapper}
            onClick={(e) => {
              if (
                e.target.nodeName === "DIV" &&
                !e.target.attributes.hasOwnProperty("data-noclose")
              ) {
                setOpenChangePassword(false);
              }
            }}
          >
            <div
              data-noclose
              className={s.twoFactAuthContent}
              style={{ maxWidth: "800px" }}
            >
              <div
                onClick={() => setOpenChangePassword(!openChangePassword)}
                className={s.closeBtn}
              >
                <VscChromeClose />
              </div>
              <span data-noclose className={s.title}>
                {t("settings.changePass")}
              </span>
              <div data-noclose className={s.authContent}>
                <div data-noclose className={s.inputBlock}>
                  <label data-noclose>{t("settings.oldPass")}</label>
                  <input
                    data-noclose
                    value={passwordChange.oldPassword}
                    onChange={(e) => {
                      if (!e.target.value.includes(" ")) {
                        setPasswordChange((state) => {
                          return { ...state, oldPassword: e.target.value };
                        });
                      }
                    }}
                    className={s.changePassInput}
                    type="text"
                    placeholder={t("login.passwordPlaceholder")}
                  />
                </div>
                <div data-noclose className={s.inputBlock}>
                  <label data-noclose>{t("settings.newPass")}</label>
                  <input
                    data-noclose
                    value={passwordChange.password}
                    onChange={(e) => {
                      if (!e.target.value.includes(" ")) {
                        setPasswordChange((state) => {
                          return { ...state, password: e.target.value };
                        });
                      }
                    }}
                    className={`${s.changePassInput} ${s.inputWithIcon}`}
                    type={type.pass}
                    placeholder={t("login.passwordPlaceholder")}
                    style={getValidateStyles("i")}
                    onFocus={() => setPasswordValidate(true)}
                    onBlur={() => setPasswordValidate(false)}
                  />
                  <div
                    data-noclose
                    onClick={() =>
                      type.pass === "password"
                        ? setType((state) => {
                            return { ...state, pass: "text" };
                          })
                        : setType((state) => {
                            return { ...state, pass: "password" };
                          })
                    }
                    className={s.typeChangeBtn}
                  >
                    {type.pass === "password" ? (
                      <IconWrapper
                        data-noclose
                        iconComponent={<FaEyeSlash data-noclose />}
                      />
                    ) : (
                      <IconWrapper
                        data-noclose
                        iconComponent={<FaEye data-noclose />}
                      />
                    )}
                  </div>
                  {passwordValidate && resizeBig ? (
                    <div className={s.passValidateWrapper}>
                      <span style={getValidateStyles(0)}>
                        {t("errors.passContain")}
                      </span>
                      <ul>
                        <li style={getValidateStyles(6)}>
                          {t("errors.latin")}
                        </li>
                        <li style={getValidateStyles(5)}>
                          {t("errors.minLength")}
                        </li>
                        <li style={getValidateStyles(1)}>{t("errors.num")}</li>
                        <li style={getValidateStyles(2)}>{t("errors.lett")}</li>
                        <li style={getValidateStyles(3)}>
                          {t("errors.uppLett")}
                        </li>
                        <li style={getValidateStyles(4)}>
                          {t("errors.specSymb")}
                        </li>
                      </ul>
                    </div>
                  ) : null}
                </div>

                {passwordValidate && resizeSmall ? (
                  <div className={s.passValidateWrapper}>
                    <span style={getValidateStyles(0)}>
                      {t("errors.passContain")}
                    </span>
                    <ul>
                      <li style={getValidateStyles(5)}>
                        {t("errors.minLength")}
                      </li>
                      <li style={getValidateStyles(1)}>{t("errors.num")}</li>
                      <li style={getValidateStyles(2)}>{t("errors.lett")}</li>
                      <li style={getValidateStyles(3)}>
                        {t("errors.uppLett")}
                      </li>
                      <li style={getValidateStyles(4)}>
                        {t("errors.specSymb")}
                      </li>
                    </ul>
                  </div>
                ) : null}
                <div data-noclose className={s.inputBlock}>
                  <label data-noclose>{t("settings.newPass")}</label>
                  <input
                    data-noclose
                    value={passwordChange.confirmPassword}
                    onChange={(e) => {
                      if (!e.target.value.includes(" ")) {
                        setPasswordChange((state) => {
                          return { ...state, confirmPassword: e.target.value };
                        });
                      }
                    }}
                    className={`${s.changePassInput} ${s.inputWithIcon}`}
                    type={type.repeat}
                    placeholder={t("login.passwordPlaceholder")}
                    style={
                      !passwordValidate &&
                      passwordChange.password !==
                        passwordChange.confirmPassword &&
                      passwordChange.confirmPassword.length
                        ? { border: "1px solid #ff6464" }
                        : null
                    }
                  />
                  <div
                    data-noclose
                    onClick={() =>
                      type.repeat === "password"
                        ? setType((state) => {
                            return { ...state, repeat: "text" };
                          })
                        : setType((state) => {
                            return { ...state, repeat: "password" };
                          })
                    }
                    className={s.typeChangeBtn}
                  >
                    {type.repeat === "password" ? (
                      <IconWrapper
                        iconComponent={<FaEyeSlash data-noclose />}
                      />
                    ) : (
                      <IconWrapper iconComponent={<FaEye data-noclose />} />
                    )}
                  </div>
                </div>

                <div data-noclose className={s.footer}>
                  <button
                    data-noclose
                    className={s.changePasswordBtn}
                    disabled={
                      !passwordChange.confirmPassword ||
                      !passwordChange.oldPassword ||
                      !passwordChange.password ||
                      passwordChange.confirmPassword !== passwordChange.password
                    }
                    type="button"
                    onClick={() => changePassword()}
                  >
                    {t("settings.savePass")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className={s.footer}>
          <button
            type="button"
            onClick={() => setOpenChangePassword(!openChangePassword)}
          >
            {t("settings.changePass")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
