import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { VscChromeClose } from "react-icons/vsc";
import { AlertContext } from "../../../../App";
import {
  GET_CONE_WALLETS,
  GET_CURRENCIES_TO_PAY,
  GET_EXCHANGE_RATE,
  UPDATE_REPLENISHMENT,
  setBuyConePopup,
  setInitialBuyCone,
} from "../../../../redux/reducers/buyConeReducer";
import s from "./BuyConePopup.module.css";
import { BuyConeStepOne } from "./BuyConeStepOne/BuyConeStepOne";
import Loader from "../../../Loader/Loader";
import { BuyConeStepTwo } from "./BuyConeStepTwo/BuyConeStepTwo";
import { BuyConeNavigationButtons } from "./components/BuyConeNavigationButtons/BuyConeNavigationButtons";
import { useSocket } from "../../../../hooks/UseSocket";
import { BuyConeStepThree } from "./BuyConeStepThree/BuyConeStepThree";
import {
  availableCurrencies,
  walletSystems,
} from "../../../../utils/constanst";
import { getHashrate } from "../../../../redux/reducers/addWalletReducer";
import { BuyConeStepThreeBtc } from "./BuyConeStepThreeBtc/BuyConeStepThreeBtc";

export const BuyConePopup = ({ replenishmentId = "" }) => {
  const socket = useSocket();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { inputs, replenishment, coneWallets, walletId, currenciesToPay } =
    useSelector((store) => store.buyCurrency);

  const alert = useContext(AlertContext);

  const [isDeleting, setIsDeleting] = useState(false);
  const [step, setStep] = useState(1);
  const [form, setForm] = useState({
    coneAmount: null,
    currencyAmount: null,
    coneWalletId:
      coneWallets.find((el) => el.value === walletId) || coneWallets[0],
    currencyToPay: currenciesToPay[0],
    walletSystem: walletSystems[0],
  });

  const setDefaultForm = () => {
    setForm({
      coneAmount: "",
      coneWalletId:
        coneWallets.find((el) => el.value === walletId) || coneWallets[0],
      currencyToPay: currenciesToPay[0] || "",
      currencyAmount: "",
    });
  };

  const getStepTitle = () => {
    switch (step) {
      case 1:
        return t("buyConePopup.stepOne");
      case 2:
        return t("buyConePopup.stepTwo");
      case 3:
        return t("buyConePopup.stepThree");
      default: {
        t("buyConePopup.stepOne");
      }
    }
  };

  const getDefaultValues = () => {
    dispatch(
      {
        type: GET_EXCHANGE_RATE,
        currency: form?.currencyToPay?.value || currenciesToPay[0].value,
        amount: form?.currencyToPay?.minCone || currenciesToPay[0].minCone,
        alert,
        t,
        reverse: true,
      },
      []
    );
  };

  const setBuyFormByKey = (objectToSet) => {
    setForm({
      ...form,
      ...objectToSet,
    });
  };

  const onClose = () => {
    dispatch(setBuyConePopup({ isPopup: false, walletId: null }));
    dispatch(setInitialBuyCone());
    dispatch({ type: GET_CURRENCIES_TO_PAY, t, alert });
  };

  useEffect(() => {
    setIsDeleting(false);
  }, [step]);

  useEffect(() => {
    setForm({
      ...form,
      currencyAmount: inputs.currency,
      coneAmount: inputs.cone,
    });
  }, [inputs]);

  useEffect(() => {
    dispatch({ type: GET_CONE_WALLETS, t, alert });
    if (replenishmentId) {
      dispatch({ type: UPDATE_REPLENISHMENT, t, alert, id: replenishmentId });
    }
  }, []);

  useEffect(() => {
    if (currenciesToPay.length) {
      setForm({
        ...form,
        currencyToPay: currenciesToPay[0],
      });
      getDefaultValues();
    }
  }, [currenciesToPay]);

  useEffect(() => {
    if (replenishment) {
      setStep(2);
    }
  }, [replenishment]);

  useEffect(() => {
    setForm({
      ...form,
      coneWalletId:
        coneWallets.find((el) => el.value === walletId) || coneWallets[0],
    });
  }, [coneWallets, walletId]);

  useEffect(() => {
    if (socket.updated) {
      if (currenciesToPay.length && step === 1) {
        dispatch(
          {
            type: GET_EXCHANGE_RATE,
            currency: form.currencyToPay?.value,
            amount: form.coneAmount,
            alert,
            t,
            reverse: true,
            minCone: form.currencyToPay?.minCone,
          },
          []
        );
      }
      socket.setIsUpdated(false);
    }
    if (socket?.repIdUpdate?.id) {
      dispatch({
        type: UPDATE_REPLENISHMENT,
        t,
        alert,
        id: socket?.repIdUpdate?.id,
      });
      if (socket?.repIdUpdate?.status === "DONE") {
        dispatch(getHashrate());
      }
      socket.setRepIdUpdate(null);
    }
  }, [socket]);

  return (
    <div
      onMouseDown={(e) => {
        if (
          e.target.nodeName === "DIV" &&
          !e.target.attributes.hasOwnProperty("data-noclose")
        ) {
          onClose();
        }
      }}
      className={s.wrapper}
    >
      {coneWallets.length ? (
        <div
          style={step === 3 || step === 2 ? { maxWidth: "433px" } : {}}
          className={s.content}
          data-noclose
        >
          <div data-noclose className={s.header}>
            <div data-noclose className={s.title}>
              {getStepTitle()}
            </div>
            <div data-noclose onClick={onClose} className={s.closeBtn}>
              <VscChromeClose />
            </div>
          </div>

          {step === 1 && !replenishmentId ? (
            <BuyConeStepOne
              setForm={setBuyFormByKey}
              form={form}
              replenishmentId={replenishmentId}
              close={onClose}
            />
          ) : null}

          {step === 2 ? (
            <BuyConeStepTwo
              setStep={setStep}
              setForm={setBuyFormByKey}
              form={form}
              isDeleting={isDeleting}
              setIsDeleting={setIsDeleting}
              getDefaultValues={getDefaultValues}
              close={onClose}
            />
          ) : null}

          {step === 3 ? (
            replenishment.currency === availableCurrencies[2].name ? <BuyConeStepThreeBtc/> : (
              <BuyConeStepThree
                close={onClose}
                setStep={setStep}
                setForm={setBuyFormByKey}
                form={form}
              />
            )
          ) : null}

          <BuyConeNavigationButtons
            step={step}
            setStep={setStep}
            form={form}
            setDefaultForm={setDefaultForm}
            getDefaultValues={getDefaultValues}
            close={onClose}
            setIsDeleting={setIsDeleting}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};
