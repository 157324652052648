import s from "./HistoryItem.module.css";
import Info from "../../../assets/Info.svg";
import { useTranslation } from "react-i18next";

export const HistoryItem = ({ el, openErrorPopup, setOpenErrorPopup }) => {
  const { t } = useTranslation();

  const error = el?.status === "Ошибка" || el?.status === "Error";
  const accrued = el?.status === "Начислено" || el?.status === "Accrued";

  // const [openErrorPopup, setOpenErrorPopup] = useState(false);

  const getIncome = (inc) => {
    let int = inc?.split(".")[0];
    let dec = inc?.split(".")[1]?.substr(0, 8);
    if (int && dec) {
      return `${int}.${dec}`;
    } else {
      return int;
    }
  };

  return (
    <div
      className={s.row}
      onClick={() => (error ? setOpenErrorPopup(true) : null)}
      style={error ? { cursor: "pointer" } : null}
    >
      <div>{`${el?.processing_date?.split("T")[0].split("-")[2]}.${
        el?.processing_date?.split("T")[0].split("-")[1]
      }.${el?.processing_date?.split("T")[0].split("-")[0]}`}</div>
      <div data-title={el.token}>
        <div className={s.token}>{el.token?.replace("CONE", "")}</div>
      </div>
      <div data-title={el?.pool_income}>{getIncome(el?.pool_income)}</div>
      <div data-title={el?.hosting_cost}>{getIncome(el?.hosting_cost)}</div>
      <div data-title={el?.income}>{getIncome(el?.income)}</div>
      <div>{el?.status}</div>
      {accrued ? (
        <div className={s.info} data-info={t("settings.info")}>
          <img className={s.image} src={Info} alt="atention" />
        </div>
      ) : null}
    </div>
  );
};
