import * as axios from "axios";
const BACKEND_API = process.env.REACT_APP_BACKEND_URL;

const authFetch = (method, url, data) => {
  let preAuth = document.cookie?.split("BIT/")[1];
  let auth = {
    token: preAuth?.split("=")[1],
    type: preAuth?.split("=")[0],
  };
  return axios({
    method,
    url,
    data,
    headers: {
      Authorization: `${auth.type} ${auth.token}`,
    },
  });
};

export const api = {
  signUp(data) {
    return axios.post(`${BACKEND_API}/auth/sign-up`, data).then((res) => {
      return res;
    });
  },
  signIn(data) {
    return axios.post(`${BACKEND_API}/auth/sign-in`, data).then((res) => {
      return res;
    });
  },
  verifyAccount(data) {
    return axios
      .post(`${BACKEND_API}/auth/sign-up/verification`, data)
      .then((res) => {
        return res;
      });
  },
  resendVerifyCode(data) {
    return axios
      .post(`${BACKEND_API}/auth/sign-up/resend-code`, data)
      .then((res) => {
        return res;
      });
  },
  setNewPassword(data) {
    return axios.post(`${BACKEND_API}/auth/password/new`, data).then((res) => {
      return res;
    });
  },
  resendNewPasswordCode(data) {
    return axios
      .post(`${BACKEND_API}/auth/password/resend-code`, data)
      .then((res) => {
        return res;
      });
  },
  getWallets(page) {
    return authFetch("get", `${BACKEND_API}/wallets/?page=${page}`);
  },
  deleteWallet(data) {
    return authFetch("delete", `${BACKEND_API}/wallets`, data);
  },
  addBtcWallet(data) {
    return authFetch("post", `${BACKEND_API}/wallets/btc/add`, data);
  },
  getVerificationPhaseForCone(data) {
    return authFetch(
      "post",
      `${BACKEND_API}/wallets/cone/verification-phrase`,
      data
    );
  },
  addConeWallet(data) {
    return authFetch("post", `${BACKEND_API}/wallets/cone/add`, data);
  },
  getHashrate() {
    return authFetch("get", `${BACKEND_API}/wallets/hashrate`);
  },
  add2FA() {
    return authFetch("post", `${BACKEND_API}/settings/tfa/add`);
  },
  verify2FA(data) {
    return authFetch("post", `${BACKEND_API}/settings/tfa/verification`, data);
  },
  remove2FA(data) {
    return authFetch("delete", `${BACKEND_API}/settings/tfa/remove`, data);
  },
  getSettings() {
    return authFetch("get", `${BACKEND_API}/settings`);
  },
  updateLanguage(data) {
    return authFetch("post", `${BACKEND_API}/settings/language/update`, data);
  },
  updatePassword(data) {
    return authFetch("post", `${BACKEND_API}/settings/password/update`, data);
  },
  getPaymentHistory(page) {
    return authFetch("get", `${BACKEND_API}/payments/history/?page=${page}`);
  },
  getReplenishmentsHistory(page) {
    return authFetch("get", `${BACKEND_API}/replenishment/history?page=${page}`);
  },
  getConeWallets() {
    return authFetch("get", `${BACKEND_API}/wallets/?cone_list=true`);
  },
  getExchangeCurrencies() {
    return authFetch("get", `${BACKEND_API}/replenishment/currencies`);
  },
  calculateExchange(data) {
    return authFetch("post", `${BACKEND_API}/replenishment/calculate`, data);
  },
  createReplenishment(data) {
    return authFetch("post", `${BACKEND_API}/replenishment`, data);
  },
  deleteReplenishment(data) {
    return authFetch("delete", `${BACKEND_API}/replenishment`, data);
  },
  getReplenishmentToWatch(data) {
    return authFetch('get', `${BACKEND_API}/replenishment?id=${data}`)
  },
};

export const getError = (error) => {
  if (error.response) {
    return error.response;
  } else if (error.request) {
    return error.request;
  } else {
    return error;
  }
};
