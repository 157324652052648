export const SET_POPUP_STATE = "SET_POPUP_STATE";
export const GET_EXCHANGE_RATE = "GET_EXCHANGE_RATE";
export const SET_EXCHANGE_RATE = "SET_EXCHANGE_RATE";
export const SET_CONE_WALLETS = "SET_CONE_WALLETS";
export const GET_CONE_WALLETS = "GET_CONE_WALLETS";
export const SET_CURRENCIES_TO_PAY = "SET_CURRENCIES_TO_PAY";
export const GET_CURRENCIES_TO_PAY = "GET_CURRENCIES_TO_PAY";
export const SET_EXCHANGE_AMOUNT = "SET_EXCHANGE_AMOUNT";
export const GET_EXCHANGE_AMOUNT = "GET_EXCHANGE_AMOUNT";
export const SET_NETWORKS = "GET_NETWORKS";
export const CREATE_REPLENISHMENT = "CREATE_REPLENISHMENT";
export const UPDATE_REPLENISHMENT = "UPDATE_REPLENISHMENT";
export const SET_REPLENISHMENT = "SET_REPLENISHMENT";
export const DELETE_REPLENISHMENT = "DELETE_REPLENISHMENT";
export const SET_LOADING = "SET_LOADING";
export const SET_INTIAL = "SET_INITIAL";

const initialState = {
  isPopup: false,
  exchangeRate: `0`,
  exchangeTotal: `0`,
  walletId: "",
  coneWallets: [],
  currenciesToPay: [],
  networks: [],
  replenishment: null,
  isLoading: false,
  fee: {
    usd: 0,
    currency: 0,
  },
  inputs: {
    currency: 0,
    cone: 0,
  },
};

export default function buyCurrencyReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INTIAL:
      return {
        ...initialState,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_POPUP_STATE:
      return {
        ...state,
        isPopup: action.payload.isPopup,
        walletId: action.payload.walletId || "",
      };
    case SET_EXCHANGE_RATE:
      return {
        ...state,
        exchangeRate: action.payload?.rate || 0,
        exchangeTotal: action.payload?.total || 0,
        fee: action.payload?.fee || state.fee,
        inputs: action.payload?.inputs || state.inputs,
      };
    case SET_CONE_WALLETS:
      return {
        ...state,
        coneWallets: action.payload,
      };
    case SET_CURRENCIES_TO_PAY:
      return {
        ...state,
        currenciesToPay: action.payload,
      };
    case SET_NETWORKS:
      return {
        ...state,
        networks: action.payload,
      };
    case SET_REPLENISHMENT:
      return {
        ...state,
        replenishment: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}

export const setNetworks = (payload) => ({
  type: SET_NETWORKS,
  payload,
});

export const setReplenishment = (payload) => ({
  type: SET_REPLENISHMENT,
  payload,
});

export const setConeWallets = (payload) => ({
  type: SET_CONE_WALLETS,
  payload,
});

export const setCurrenciesToPay = (payload) => ({
  type: SET_CURRENCIES_TO_PAY,
  payload,
});

export const setBuyConePopup = (payload) => ({
  type: SET_POPUP_STATE,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setInitialBuyCone = (payload) => ({
  type: SET_INTIAL,
  payload,
});

export const setExchangeRate = (payload) => ({
  type: SET_EXCHANGE_RATE,
  payload,
});
