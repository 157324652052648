import React, { useState, useEffect } from "react";
import s from "./ChartWithFilters.module.css";
import { Area } from "@ant-design/plots";
import { useTranslation } from "react-i18next";

const yearData = [
  { time: "Янв", value: 40000 },
  { time: "Фев", value: 10000 },
  { time: "Март", value: 15000 },
  { time: "Апр", value: 25000 },
  { time: "Май", value: 30000 },
  { time: "Июн", value: 20000 },
  { time: "Июл", value: 14000 },
  { time: "Авг", value: 17000 },
  { time: "Сент", value: 10000 },
  { time: "Окт", value: 30000 },
  { time: "Ноя", value: 32000 },
  { time: "Дек", value: 10000 },
];

const monthData = [
  { time: "1", value: 10000 },
  { time: "2", value: 11250 },
  { time: "3", value: 12350 },
  { time: "4", value: 11700 },
  { time: "5", value: 13120 },
  { time: "6", value: 17452 },
  { time: "7", value: 19452 },
  { time: "8", value: 17545 },
  { time: "9", value: 21278 },
  { time: "10", value: 24454 },
  { time: "11", value: 18452 },
  { time: "12", value: 24222 },
  { time: "13", value: 21001 },
  { time: "14", value: 12455 },
  { time: "15", value: 19454 },
  { time: "16", value: 24242 },
  { time: "17", value: 26545 },
  { time: "18", value: 27823 },
  { time: "19", value: 25467 },
  { time: "20", value: 24567 },
  { time: "21", value: 21242 },
  { time: "22", value: 12457 },
  { time: "23", value: 29545 },
  { time: "24", value: 18545 },
  { time: "25", value: 26452 },
  { time: "26", value: 29454 },
  { time: "27", value: 31023 },
  { time: "28", value: 38123 },
];

const dayData = [
  { time: "01", value: 10000 },
  { time: "02", value: 11250 },
  { time: "03", value: 12350 },
  { time: "04", value: 11700 },
  { time: "05", value: 13120 },
  { time: "06", value: 17452 },
  { time: "07", value: 19452 },
  { time: "08", value: 17545 },
  { time: "09", value: 21278 },
  { time: "10", value: 24454 },
  { time: "11", value: 18452 },
  { time: "12", value: 24222 },
  { time: "13", value: 21001 },
  { time: "14", value: 12455 },
  { time: "15", value: 19454 },
  { time: "16", value: 24242 },
  { time: "17", value: 26545 },
  { time: "18", value: 27823 },
  { time: "19", value: 25467 },
  { time: "20", value: 24567 },
  { time: "21", value: 21242 },
  { time: "22", value: 12457 },
  { time: "23", value: 29545 },
];

export const ChartWithFilters = ({}) => {
  const [filter, setFilter] = useState(1);
  const [isCurrencyDropdown, setIsCurrencyDropdown] = useState(false);
  const [currency, setCurrency] = useState("$");
  const { t } = useTranslation();

  const [config, setConfig] = useState({
    data: yearData,
    xField: "time",
    yField: "value",
    xAxis: {
      range: [0, 1],
    },
    areaStyle: {
      fill: "#00B387",
    },
    line: {
      color: "green",
    },
  });

  const setChartArray = () => {
    switch (filter) {
      case 1:
        setConfig({
          ...config,
          data: yearData,
          areaStyle: {
            fill: currency === "$" ? "#00B387" : "#f46060d9",
          },
          line: {
            color: currency === "$" ? "green" : "#f22929d9",
          },
        });
        break;
      case 2:
        setConfig({
          ...config,
          data: monthData,
          areaStyle: {
            fill: currency === "$" ? "#00B387" : "#f46060d9",
          },
          line: {
            color: currency === "$" ? "green" : "#f22929d9",
          },
        });
        break;
      case 3:
        setConfig({
          ...config,
          data: dayData,
          areaStyle: {
            fill: currency === "$" ? "#00B387" : "#f46060d9",
          },
          line: {
            color: currency === "$" ? "green" : "#f22929d9",
          },
        });
        break;
      default:
        setConfig({
          ...config,
          data: yearData,
          areaStyle: {
            fill: currency === "$" ? "#00B387" : "#f46060d9",
          },
          line: {
            color: currency === "$" ? "green" : "#f22929d9",
          },
        });
        break;
    }
  };

  useEffect(() => {
    setChartArray();
  }, [filter, currency]);

  return (
    <div className={s.chart}>
      <div className={s.chart_header}>
        <div className={s.chart_name}>
          {t("analytics.statistic")}
          <span
            style={
              currency === "HashRate" ? { right: "_55px" } : { right: "_10px" }
            }
            onClick={() => setIsCurrencyDropdown(!isCurrencyDropdown)}
            className={s.chart_name_absolute}
          >
            {currency}
          </span>
          {isCurrencyDropdown ? (
            <div className={s.currency_list}>
              <div
                onClick={() => {
                  setCurrency("$");
                  setIsCurrencyDropdown(false);
                }}
                className={
                  currency === "$"
                    ? `${s.currency_list_item} ${s.item_active}`
                    : `${s.currency_list_item}`
                }
              >
                $
              </div>
              <div
                onClick={() => {
                  setCurrency("HashRate");
                  setIsCurrencyDropdown(false);
                }}
                className={
                  currency === "HashRate"
                    ? `${s.currency_list_item} ${s.item_active}`
                    : `${s.currency_list_item}`
                }
              >
                Hashrate
              </div>
            </div>
          ) : null}
        </div>
        <div className={s.chart_filters}>
          <div
            onClick={() => setFilter(1)}
            className={
              filter === 1 ? `${s.filter_item_active}` : `${s.filter_item}`
            }
          >
            {t("analytics.yearFilter")}
          </div>
          <div
            onClick={() => setFilter(2)}
            className={
              filter === 2 ? `${s.filter_item_active}` : `${s.filter_item}`
            }
          >
            {t("analytics.monthFilter")}
          </div>
          <div
            onClick={() => setFilter(3)}
            className={
              filter === 3 ? `${s.filter_item_active}` : `${s.filter_item}`
            }
          >
            {t("analytics.dayFilter")}
          </div>
        </div>
      </div>
      <div className={s.chart_image}>
        <Area {...config} />
      </div>
    </div>
  );
};
