import { useTranslation } from "react-i18next";
import s from "./BuyConeStepThreeBtc.module.css";
import { useContext, useEffect, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { useSelector } from "react-redux";
import { copyToClipboard, reduceString } from "../../../../../utils/helpers";
import { VscCopy } from "react-icons/vsc";
import { AlertContext } from "../../../../../App";

export const BuyConeStepThreeBtc = ({ form, setForm, setStep }) => {
  const { replenishment } = useSelector((store) => store.buyCurrency);
  const alert = useContext(AlertContext);
  const [qrData, setQrData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setQrData(
      `bitcoin:${replenishment.replenishment_address}?amount=${replenishment.replenishment_amount}`
    );
  }, []);

  return (
    <div data-noclose className={s.content}>
      <div data-noclose className={s.qrdata}>
        <QRCodeSVG
          data-noclose
          value={`bitcoin:${replenishment.replenishment_address}?amount=${replenishment.replenishment_amount}`}
          width="265"
          height="265"
        />
        <div data-noclose className={s.qrdata_info}>
          <p>{t("buyConePopup.qrInfoOne")}</p>
          <p>{t("buyConePopup.qrInfoTwo")}</p>
        </div>
      </div>
      <div className={s.delim}></div>
      <div data-noclose className={s.copyData}>
        <div data-noclose className={s.copyDataItem}>
          {t("buyConePopup.qrInfoTextOne")}
          <div className={s.copyDataItemLink}>
            <span className={s.text}>
              {reduceString(replenishment.replenishment_address)}
            </span>
            <div
              data-noclose
              onClick={(e) =>
                copyToClipboard(
                  e,
                  t,
                  alert,
                  replenishment.replenishment_address
                )
              }
              className={s.copyHandler}
            >
              <VscCopy data-noclose />
            </div>
          </div>
        </div>
        <div data-noclose className={s.copyDataItem}>
          {t("buyConePopup.qrInfoTextTwo")}
          <div className={s.copyDataItemLink}>
            <span className={s.text}>
              {reduceString(replenishment.replenishment_amount)} BTC
            </span>
            <div
              data-noclose
              onClick={(e) =>
                copyToClipboard(
                  e,
                  t,
                  alert,
                  replenishment.replenishment_address
                )
              }
              className={s.copyHandler}
            >
              <VscCopy data-noclose />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
