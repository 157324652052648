import React from "react";
import s from "./OperationItem.module.css";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/ru";
import { useSelector } from "react-redux";
import { useCallback } from "react";

export const OperationItem = ({ item }) => {
  const { lang } = useSelector((store) => store.settings);
  const getDate = useCallback(() => {
    const date = moment(item.date);
    if (lang === "ru") {
      return date.locale("ru").format("DD MMM YYYY");
    } else {
      moment.locale("en");
      return date.locale("en").format("DD MMM YYYY");
    }
  }, [lang]);

  return (
    <div className={s.itemCard}>
      <div className={s.generalInfo}>
        <div className={s.title}>{item.title}</div>
        <div className={s.date}>{getDate()}</div>
      </div>
      <div className={s.valueInfo}>
        <div
          className={
            item.value >= 0 ? `${s.value} ${s.green}` : `${s.value} ${s.red}`
          }
        >
          {item.value >= 0 ? "+" : "-"}
          {item.value} {item.currency}
        </div>
        <div className={s.totalValue}>$ {item.total}</div>
      </div>
    </div>
  );
};
