import React from "react";
import s from "./SubInformation.module.css";

export const SubInformation = ({ imageSource, title, amount }) => {
  return (
    <div className={s.content}>
      <img className={s.image} src={imageSource} alt="sub_info" />
      <div className={s.title}>{title}</div>
      <div className={s.amount}>$ {amount}</div>
    </div>
  );
};
