import { createStore, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "@redux-saga/core";
import { all, fork } from "redux-saga/effects";
import sagas from "./sagas/index";
import reducers from "./reducers";
import { composeWithDevTools } from "@redux-devtools/extension";

const sagaMiddleWare = createSagaMiddleware();

const rootReducer = combineReducers({ ...reducers });

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleWare))
);
// const store = createStore(
//   rootReducer,
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(applyMiddleware(sagaMiddleWare))
// );

const saga = function* rootSaga() {
  yield all([...sagas].map(fork));
};

sagaMiddleWare.run(saga);

export default store;
