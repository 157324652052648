import { useContext } from "react";
import { useDispatch } from "react-redux";
import { AlertContext } from "../../../../App";
import { addBtcWallet } from "../../../../redux/reducers/addWalletReducer";
import s from "./AddWallet.module.css";
import { Alert } from "../../../Alert/Alert";

export const AddWalletContent = ({
  t,
  nextStep,
  walletAdress,
  setWalletAdress,
}) => {
  const dispatch = useDispatch();
  const alert = useContext(AlertContext);

  const nextClick = () => {
    dispatch(
      addBtcWallet(
        {
          address: walletAdress,
          nextStep,
        },
        alert,
        t
      )
    );
  };

  return (
    <>
      <div className={s.addWallet} data-noclose>
        <span data-noclose className={s.addWalletTitle}>
          {t("main.addWallet.title")}
        </span>

        <div data-noclose className={s.addWalletAddress}>
          <label data-noclose htmlFor="walletAdress">
            {t("main.addWallet.walletAdress")}
          </label>
          <input
            data-noclose
            onChange={(e) => {
              if (!e.target.value.includes(" ")) {
                setWalletAdress(e.target.value);
              }
            }}
            value={walletAdress}
            type={"text"}
            id="walletAdress"
            name="walletAdress"
            placeholder={t("main.addWallet.walletAdressPlaceholder")}
            className={s.addWalletInput}
          />
        </div>
      </div>
      <div data-noclose className={s.nextStep}>
        <button
          data-noclose
          onClick={nextClick}
          disabled={walletAdress?.length < 26 || walletAdress?.length > 62}
        >
          {t("main.next")}
        </button>
      </div>
    </>
  );
};
