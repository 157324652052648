import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";
import { addError } from "../../redux/reducers/signUpReducer";
import s from "./LoginPage.module.css";
import LoginForm from "./components/LoginForm/LoginForm";
import LanguageSelector from "../../common-components/LanguageSelector/LanguageSelector";

const LoginPage = () => {
  const location = useLocation();
  const currentLang = localStorage.getItem("i18nextLng");
  const dispatch = useDispatch();
  const [lang, setLang] = useState(currentLang);

  const { t } = useTranslation();

  const errors = useSelector((state) => state.signUp.errors);
  const auth = document.cookie?.indexOf("bearer") !== -1;

  useEffect(() => {
    return () => dispatch(addError(null));
  }, [dispatch]);

  useEffect(() => {}, [errors]);

  return (
    <>
      {auth ? (
        <Navigate to={"/dashboard"} state={{ from: location }} />
      ) : (
        <div className={s.wrapper}>
          <div className={s.login}>
            <div className={s.loginForm}>
              <LanguageSelector setLang={setLang} lang={lang} />
              <div className={s.title}>
                <span>{t("login.enter")}</span>
              </div>
              <LoginForm />
              <div className={s.noProfileWrapper}>
                <div>
                  <span className={s.noProfile}>{t("login.noProfile")}</span>
                  <Link to="/signup" className={s.noProfileLink}>
                    <span>{t("login.registration")}</span>
                  </Link>
                </div>
                <Link
                  to="/resetPassword"
                  className={s.noProfileLink}
                  style={{ marginTop: "8px" }}
                >
                  <span>{t("login.forgotPass")}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginPage;
