import { takeEvery, put, call } from "redux-saga/effects";
import { api, getError } from "../../api/api";
import {
  addWalletFail,
  addWallets,
  ADD_BTC_WALLET,
  ADD_CONE_WALLET,
  deleteWallets,
  DELETE_WALLETS,
  GET_HASHRATE,
  GET_MORE_WALLETS,
  GET_SIGNATURE_PHRASE_VALUE,
  GET_WALLETS,
  setHashrate,
  setLoading,
  setSignaturePhraseValue,
  setWallets,
  getWallets,
  getHashrate,
} from "../reducers/addWalletReducer";

function* getHashrateWorker() {
  try {
    const data = yield call(api.getHashrate);
    if (document.cookie?.indexOf("bearer") !== -1) {
      yield put(setHashrate(data.data));
    }
  } catch (error) {
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
}

function* getWalletValuesWorker({ payload, alert, t }) {
  try {
    const data = yield call(api.getVerificationPhaseForCone, payload);
    if (data.status === 200) {
      yield put(setSignaturePhraseValue(data.data));
    }
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return;
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else if (
      getError(error).data.errors[0].message.includes("Wallet already exist")
    ) {
      let lang = localStorage.getItem("i18nextLng");
      lang === "ru"
        ? alert("error", "Кошелёк уже добавлен")
        : alert("error", `${getError(error).data.errors[0].message}`);
    } else if (
      getError(error).data.errors[0].message.includes("Invalid address format")
    ) {
      alert("error", t("errors.invalidAddress"));
    } else {
    }
  }
}

function* addConeWalletWorker({ payload, alert, t }) {
  try {
    const data = yield call(api.addConeWallet, payload);
    if (data.status === 200) {
      if (
        window.localStorage.getItem("e") ===
        window.localStorage.getItem("newEmail")
      ) {
        window.localStorage.removeItem("newEmail");
      }
    }
    yield put(getWallets(1, alert, t));
    yield put(getHashrate());
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return;
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else if (
      getError(error).data.errors[0].message.includes("Invalid address format")
    ) {
      alert("error", t("errors.invalidAddress"));
    } else yield put(addWalletFail(getError(error).data.errors));
  }
}

function* addBtcWalletWorker({ payload, alert, t }) {
  try {
    const data = yield call(api.addBtcWallet, payload);
    if (data.status === 200) {
      yield put(addWalletFail({}));
      payload.nextStep(2);
      if (
        window.localStorage.getItem("e") ===
        window.localStorage.getItem("newEmail")
      ) {
        window.localStorage.removeItem("newEmail");
      }
    }
    yield put(getWallets(1, alert, t));
    yield put(getHashrate());
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return;
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else if (
      getError(error).data.errors[0].message.includes("Invalid address format")
    ) {
      alert("error", t("errors.invalidAddress"));
    } else if (
      getError(error).data.errors[0].message.includes("Invalid address network")
    ) {
      alert("error", t("errors.invalidNetwork"));
    } else yield put(addWalletFail(getError(error).data.errors));
  }
}

function* getWalletWorker({ payload, alert, t }) {
  try {
    yield put(setLoading(true));
    const data = yield call(api.getWallets, payload);
    if (document.cookie?.indexOf("bearer") !== -1) {
      yield put(setWallets(data.data));
    }
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return;
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else if (error) {
      yield put(addWalletFail(getError(error).data.errors));
    }
  } finally {
    yield put(setLoading(false));
  }
}

function* addWalletWorker({ payload, alert, t }) {
  try {
    yield put(setLoading(true));
    const data = yield call(api.getWallets, payload);
    yield put(addWallets(data.data));
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return;
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else if (error) {
      yield put(addWalletFail(getError(error).data.errors));
    }
  } finally {
    yield put(setLoading(false));
  }
}

function* deleteWalletWorker(action) {
  try {
    if (action.payload.id) {
      const data = yield call(api.deleteWallet, {
        walletId: action.payload.id,
      });
      if (data?.status === 200) {
        yield put(getHashrate());
        yield put(deleteWallets(action.payload.id));
        action.payload.alert(
          "success",
          action.payload.t("main.confirmDelete.deleted")
        );
      }
    }
  } catch (error) {
    if (getError(error).status === 0) {
      action.payload.alert("error", action.payload.t("networkError"));
      return;
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else if (error) {
      yield put(addWalletFail(getError(error).data.errors));
    }
  }
}

export function* addWalletWatcher() {
  yield takeEvery(GET_HASHRATE, getHashrateWorker);
  yield takeEvery(GET_SIGNATURE_PHRASE_VALUE, getWalletValuesWorker);
  yield takeEvery(ADD_CONE_WALLET, addConeWalletWorker);
  yield takeEvery(ADD_BTC_WALLET, addBtcWalletWorker);
  yield takeEvery(GET_WALLETS, getWalletWorker);
  yield takeEvery(GET_MORE_WALLETS, addWalletWorker);
  yield takeEvery(DELETE_WALLETS, deleteWalletWorker);
}
