import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck, FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { addError, fetchEmail } from "../../../../redux/reducers/signUpReducer";
import ErrorsBlock from "../RegistrationError/RegistrationError";
import s from "../../Registration.module.css";
import { getValidateStyles } from "../../../../utils/helpers";
import { IconWrapper } from "../../../../common-components/IconWrapper/IconWrapper";

const RegistrationForm = ({ resizeSmall, resizeBig }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.signUp.errors);

  const [type, setType] = useState({ pass: "password", repeat: "password" });
  const [passwordValidate, setPasswordValidate] = useState(false);

  const [checkboxes, setCheckboxes] = useState({
    terms: false,
    notCitizen: false,
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirm_password: "",
    },
    onSubmit: (values) => {
      console.log(values);
      if (
        values.password.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/
        ) !== null
      ) {
        dispatch(
          fetchEmail(
            {
              ...values,
              email: values.email.toLocaleLowerCase(),
              lang: localStorage.getItem("i18nextLng"),
            },
            alert,
            t
          )
        );
      } else {
        setPasswordValidate(true);
        dispatch(addError(null));
      }
    },
  });

  const checkboxInputHandler = (n) => {
    n === "terms"
      ? setCheckboxes((state) => {
          return { ...state, terms: !state.terms };
        })
      : setCheckboxes((state) => {
          return { ...state, notCitizen: !state.notCitizen };
        });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={s.inputBlock}>
        <label htmlFor="email">{t("registration.email")}</label>
        <input
          onChange={(e) => {
            if (!e.target.value.includes(" ")) {
              formik.handleChange(e);
            }
          }}
          value={formik.values.email}
          type={"text"}
          id="email"
          name="email"
          placeholder={t("registration.emailPlaceholder")}
          style={errors ? { border: "1px solid #ff6464" } : null}
        />
      </div>

      <div className={s.inputBlock} style={{ position: "relative" }}>
        <label htmlFor="password">{t("registration.password")}</label>
        <input
          className={` ${s.inputWithIcon}`}
          onChange={(e) => {
            if (!e.target.value.includes(" ")) {
              formik.handleChange(e);
            }
          }}
          value={formik.values.password}
          type={type.pass}
          id="password"
          name="password"
          placeholder={t("registration.passwordPlaceholder")}
          style={getValidateStyles("i", formik, passwordValidate)}
          onFocus={() => setPasswordValidate(true)}
          onBlur={() => setPasswordValidate(false)}
        />
        <div
          onClick={() =>
            type.pass === "password"
              ? setType((state) => {
                  return { ...state, pass: "text" };
                })
              : setType((state) => {
                  return { ...state, pass: "password" };
                })
          }
          className={s.typeChangeBtn}
        >
          {type.pass === "password" ? (
            <IconWrapper iconComponent={<FaEyeSlash />} />
          ) : (
            <IconWrapper iconComponent={<FaEye />} />
          )}
        </div>

        {passwordValidate && resizeBig ? (
          <div className={s.passValidateWrapper}>
            <span style={getValidateStyles(0, formik, passwordValidate)}>
              {t("errors.passContain")}
            </span>
            <ul>
              <li style={getValidateStyles(6, formik, passwordValidate)}>
                {t("errors.latin")}
              </li>
              <li style={getValidateStyles(5, formik, passwordValidate)}>
                {t("errors.minLength")}
              </li>
              <li style={getValidateStyles(1, formik, passwordValidate)}>
                {t("errors.num")}
              </li>
              <li style={getValidateStyles(2, formik, passwordValidate)}>
                {t("errors.lett")}
              </li>
              <li style={getValidateStyles(3, formik, passwordValidate)}>
                {t("errors.uppLett")}
              </li>
              <li style={getValidateStyles(4, formik, passwordValidate)}>
                {t("errors.specSymb")}
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {passwordValidate && resizeSmall ? (
        <div className={s.passValidateWrapper}>
          <span style={getValidateStyles(0, formik, passwordValidate)}>
            {t("errors.passContain")}
          </span>
          <ul>
          <li style={getValidateStyles(6, formik, passwordValidate)}>
              {t("errors.minLength")}
            </li>
            <li style={getValidateStyles(5, formik, passwordValidate)}>
              {t("errors.minLength")}
            </li>
            <li style={getValidateStyles(1, formik, passwordValidate)}>
              {t("errors.num")}
            </li>
            <li style={getValidateStyles(2, formik, passwordValidate)}>
              {t("errors.lett")}
            </li>
            <li style={getValidateStyles(3, formik, passwordValidate)}>
              {t("errors.uppLett")}
            </li>
            <li style={getValidateStyles(4, formik, passwordValidate)}>
              {t("errors.specSymb")}
            </li>
          </ul>
        </div>
      ) : null}

      <div className={s.inputBlock}>
        <label htmlFor="confirm_password">
          {t("registration.repeatPassword")}
        </label>
        <input
          className={` ${s.inputWithIcon}`}
          onChange={(e) => {
            if (!e.target.value.includes(" ")) {
              formik.handleChange(e);
            }
          }}
          value={formik.values.confirm_password}
          type={type.repeat}
          id="confirm_password"
          name="confirm_password"
          placeholder={t("registration.repeatPassword")}
          style={
            !passwordValidate &&
            formik.values.password !== formik.values.confirm_password &&
            formik.values.confirm_password.length
              ? { border: "1px solid #ff6464" }
              : null
          }
        />
        <div
          onClick={() =>
            type.repeat === "password"
              ? setType((state) => {
                  return { ...state, repeat: "text" };
                })
              : setType((state) => {
                  return { ...state, repeat: "password" };
                })
          }
          className={s.typeChangeBtn}
        >
          {type.repeat === "password" ? (
            <IconWrapper iconComponent={<FaEyeSlash />} />
          ) : (
            <IconWrapper iconComponent={<FaEye />} />
          )}
        </div>
      </div>

      <div className={s.agreeInfoWrapper}>
        <div className={s.agreeInfo} style={{ margin: "24px 0 8px" }}>
          <input
            className={s.checkbox}
            type="checkbox"
            name="agree"
            id="agree"
            checked={checkboxes.terms}
            onChange={() => checkboxInputHandler("terms")}
          />
          <label htmlFor="agree">{checkboxes.terms ? <FaCheck /> : null}</label>
          <span>
            {t("registration.agree.agree1")}
            <a>{t("registration.agree.agree2")}</a>
            {t("registration.agree.agree3")}
            <a
              href={"/download/Privacy-Policy.pdf"}
              target="_blank"
              rel="noreferrer"
            >
              {t("registration.agree.agree4")}
            </a>
          </span>
        </div>
        <div className={s.agreeInfo}>
          <input
            className={s.checkbox}
            type="checkbox"
            name="notCitizen"
            id="notCitizen"
            checked={checkboxes.notCitizen}
            onChange={() => checkboxInputHandler()}
          />
          <label htmlFor="notCitizen">
            {checkboxes.notCitizen ? <FaCheck /> : null}
          </label>
          <span>{t("registration.notCitizen")}</span>
        </div>
      </div>

      {errors ? (
        <ErrorsBlock
          errors={errors}
          email={formik.values.email.toLocaleLowerCase()}
        />
      ) : null}

      <div className={s.signupBtnWrapper}>
        <button
          disabled={
            !formik.values.email ||
            !formik.values.password ||
            !formik.values.confirm_password ||
            formik.values.password !== formik.values.confirm_password ||
            !checkboxes.terms ||
            !checkboxes.notCitizen
          }
          className={s.loginBtn}
          type="submit"
        >
          {t("registration.enterButton")}
        </button>
      </div>
    </form>
  );
};

export default RegistrationForm;
