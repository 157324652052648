import { useTranslation } from "react-i18next";
import s from "./ConfirmDelete.module.css";
import { useRef } from "react";
import { UseClickOutside } from "../../../../../../../hooks/UseClickOutside";
import { VscChromeClose } from "react-icons/vsc";

export const ConfirmDelete = ({ onDelete, closePopup, isDeleting }) => {
  const { t } = useTranslation();
  const selectWrapRef = useRef(null);
  UseClickOutside(
    selectWrapRef,
    () => {
      closePopup();
    },
    isDeleting
  );

  return (
    <div onClick={(e) => e.stopPropagation()} data-noclose ref={selectWrapRef} className={s.popup}>
      <div data-noclose onClick={closePopup} className={s.closeBtn}>
        <VscChromeClose data-noclose width={16} height={16} />
      </div>
      <div data-noclose className={s.title}>
        {t("buyConePopup.deleteTitle")}
      </div>
      <div data-noclose className={s.content}>
        <div data-noclose className={s.message}>
          {t("buyConePopup.sure")}
        </div>
        <div data-noclose className={s.btns}>
          <div data-noclose onClick={closePopup} className={s.cancel}>
            {t("buyConePopup.no")}
          </div>
          <div data-noclose onClick={() => onDelete()} className={s.confirm}>
            {t("buyConePopup.yes")}
          </div>
        </div>
      </div>
    </div>
  );
};
