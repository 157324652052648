import React, { useContext, useEffect, useState } from "react";
import { VscChromeClose } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import DoneCircle from "../../../../assets/tick-circle.png";
import ErrorCircle from "../../../../assets/close-circle.png";
import s from "./AddWallet.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addConeWallet,
  addWalletFail,
  getSignaturePhraseValue,
  getWallets,
  setSignaturePhraseValue,
  setSignatureValue,
  setWalletValue,
} from "../../../../redux/reducers/addWalletReducer";
import { ethers } from "ethers";
import { AlertContext } from "../../../../App";
import { SignClient } from "@walletconnect/sign-client";
import { WalletConnectModal } from "@walletconnect/modal";
import { Alert } from "../../../Alert/Alert";
import { WalletSystemSelector } from "../../../../common-components/WalletSystemSelector/WalletSystemSelector";
import {
  walletDefaultMethods,
  walletSystems,
} from "../../../../utils/constanst";
import {
  clearAfterWalletConnect,
  closeModalWalletConnect,
  isMobile,
} from "../../../../utils/helpers";

const signMessage = async ({ walletAddress, setError, message, alert, t }) => {
  try {
    if (!window.ethereum) {
      alert("error", t("installWallet"));
      throw new Error("No crypto wallet found. Please install it.");
    }

    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    if (address !== walletAddress) {
      alert("error", t("errors.connectWalletToWebsite"));
    }
    const signature = await signer.signMessage(message);
    return {
      message,
      signature,
      address,
    };
  } catch (err) {
    setError(err.message);
  }
};

const AddWalletCone = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const error = useSelector((state) => state.addWallet.error);

  const [activeStep, setActiveStep] = useState(1);
  const [connector, setConnector] = useState(null);

  const activeClasses = (style, step) => {
    if (activeStep === step) {
      return style.activeStep;
    } else if (step < activeStep) {
      return s.completedStep;
    } else {
      return style.step;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setSignatureValue(""));
      dispatch(setSignaturePhraseValue({ phrase: "" }));
      dispatch(setWalletValue({ walletAdress: "" }));
      dispatch(addWalletFail({}));
    };
  }, []);

  const onBack = () => {
    dispatch(setSignatureValue(""));
    dispatch(setSignaturePhraseValue({ phrase: "" }));
    dispatch(setWalletValue({ walletAdress: "" }));
    dispatch(addWalletFail({}));
    setActiveStep(1);
  };

  return (
    <div
      className={s.wrapper}
      onClick={(e) => {
        if (
          e.target.nodeName === "DIV" &&
          !e.target.attributes.hasOwnProperty("data-noclose")
        ) {
          onClose();
        }
      }}
    >
      <div className={s.content} data-noclose>
        <button onClick={onClose} className={s.closeWalletBtn}>
          <VscChromeClose />
        </button>
        <div data-noclose className={s.steps} data-step={activeStep}>
          <div data-noclose className={activeClasses(s, 1)}>
            <span data-noclose>{t("main.addWallet.addWalletStep1")}</span>
          </div>
          <div data-noclose className={activeClasses(s, 2)}>
            <span data-noclose>{t("main.addWallet.addWalletStep2")}</span>
          </div>
          <div data-noclose className={activeClasses(s, 3)}>
            <span data-noclose>{t("main.addWallet.addWalletStep3")}</span>
          </div>
        </div>
        {activeStep === 1 ? (
          <AddWalletContent t={t} nextStep={() => setActiveStep(2)} />
        ) : activeStep === 2 ? (
          <Signature
            t={t}
            backStep={() => setActiveStep(1)}
            nextStep={() => setActiveStep(3)}
            setConnector={setConnector}
          />
        ) : activeStep === 3 ? (
          <Verification
            t={t}
            backStep={() => setActiveStep(2)}
            nextStep={() => setActiveStep(4)}
            connector={connector}
          />
        ) : !error?.length ? (
          <div data-noclose className={s.succesVerify}>
            <img data-noclose src={DoneCircle} />
            <h2 data-noclose>{t("main.verifySucces")}</h2>
            <span data-noclose>{t("main.addWallet.walletAdded")}</span>
            <button
              onClick={() => {
                onClose();
                dispatch(getWallets(1, alert, t));
              }}
            >
              {t("main.addWallet.onDashboard")}
            </button>
          </div>
        ) : (
          <div data-noclose className={s.succesVerify}>
            <img data-noclose src={ErrorCircle} />
            <h2 data-noclose>{t("main.verifyFail")}</h2>
            <span data-noclose>{t("main.addWallet.registrationFail")}</span>

            <div className={s.btnGroup}>
              <button
                onClick={() => {
                  onClose();
                  dispatch(getWallets(1, alert, t));
                }}
              >
                {t("main.addWallet.onDashboard")}
              </button>
              <button onClick={onBack}>{t("main.addWallet.repeat")}</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddWalletCone;

const AddWalletContent = ({ t, nextStep }) => {
  const [walletAdress, setWalletAdress] = useState("");
  const lastWallet = useSelector(
    (state) => state.addWallet.addWallet.walletAdress
  );

  const addresChange = (e) => {
    if (!e.target?.value.includes(" ")) {
      setWalletAdress(e.target.value);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (lastWallet) {
      setWalletAdress(lastWallet);
    }
  }, [lastWallet]);

  const nextClick = () => {
    if (/^0x[a-fA-F0-9]{40}$/.test(walletAdress)) {
      dispatch(setWalletValue({ walletAdress }));
      nextStep();
    } else {
      Alert("error", t("errors.invalidAddress"));
    }
  };

  return (
    <>
      <div data-noclose className={s.addWallet}>
        <span data-noclose className={s.addWalletTitle}>
          {t("main.addWallet.title")}
        </span>

        <div data-noclose className={s.addWalletAddress}>
          <label data-noclose htmlFor="walletAdress">
            {t("main.addWallet.walletAdress")}
          </label>
          <input
            data-noclose
            onChange={addresChange}
            value={walletAdress}
            type={"text"}
            id="walletAdress"
            name="walletAdress"
            placeholder={t("main.addWallet.walletAdressPlaceholder")}
            className={s.addWalletInput}
          />
        </div>
      </div>
      <div data-noclose className={s.nextStep}>
        <button
          data-noclose
          onClick={nextClick}
          disabled={!/^0x[a-fA-F0-9]{40}$/.test(walletAdress)}
        >
          {t("main.next")}
        </button>
      </div>
    </>
  );
};

const Signature = ({ t, backStep, nextStep, setConnector }) => {
  const wallet = useSelector((state) => state.addWallet?.addWallet);
  const phrase = useSelector((state) => state.addWallet?.phrase);

  const dispatch = useDispatch();

  const [signature, setSignature] = useState();
  const [error, setError] = useState();
  const [openWallets, setOpenWallets] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState("m");
  const [metamaskExists, setMetamaskExists] = useState(true);

  const alert = useContext(AlertContext);

  useEffect(() => {
    setSelectedWallet(window.ethereum ? "m" : "t");
    setMetamaskExists(Boolean(window.ethereum));
  }, []);

  const [signClient, setSignClient] = useState(null);
  useEffect(() => {
    const initSignClient = async () => {
      const newSignClient = await SignClient.init({
        projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
      });
      setSignClient(newSignClient);
    };
    initSignClient();
  }, []);

  useEffect(() => {}, [signClient?.session]);

  const trustWalletNextStep = async () => {
    const walletConnectModal = new WalletConnectModal({
      projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
      standaloneChains: ["eip155:1"],
      themeVariables: {
        "--wcm-z-index": 50000,
        "--wcm-overlay-background-color": "transparent",
      },
    });
    walletConnectModal.subscribeModal((state) => {
      if (!state.open) {
        walletConnectModal.closeModal();
      }
    });
    try {
      const { uri, approval } = await signClient.connect({
        requiredNamespaces: {
          eip155: {
            methods: walletDefaultMethods,
            chains: ["eip155:1"],
            events: ["chainChanged", "accountsChanged"],
          },
        },
      });
      if (uri) {
        walletConnectModal.openModal({ uri });
        const session = await approval();
        try {
          const result = await signClient.request({
            topic: session.topic,
            chainId: "eip155:1",
            request: {
              method: "personal_sign",
              params: [
                ethers.utils.hexlify(ethers.utils.toUtf8Bytes(phrase)),
                wallet.walletAdress,
              ],
            },
          });
          setSignature({ signature: result });
        } catch (e) {
          signClient.disconnect({
            topic: session.topic,
            reason: "Invalid data",
          });
          Alert("error", t("errors.wrongData"));
        }
        walletConnectModal.closeModal();
      }
    } catch (e) {
      walletConnectModal.closeModal();
      console.error(e);
    }
  };

  const metamaskNextStep = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    setError();
    const sig = await signMessage({
      walletAddress: wallet.walletAdress,
      setError,
      message: data.get("message"),
      alert,
      t,
    });
    if (sig) {
      setSignature(sig);
    }
  };

  useEffect(() => {
    if (wallet.walletAdress) {
      dispatch(
        getSignaturePhraseValue(
          {
            address: wallet.walletAdress,
          },
          alert,
          t
        )
      );
    }
  }, [wallet]);

  useEffect(() => {
    if (signature) {
      dispatch(setSignatureValue(signature.signature));
      nextStep();
    }
  }, [signature]);

  return (
    <form data-noclose onSubmit={metamaskNextStep}>
      <div data-noclose className={s.signWrapper}>
        <span data-noclose className={s.signTitle}>
          {t("main.signature.title")}
        </span>

        <div data-noclose className={s.signContent}>
          <span data-noclose className={s.signText}>
            {t("main.signature.copy")}
          </span>

          <div data-noclose className={s.walletInput}>
            <span data-noclose>
              {t("main.signature.wallet")}
              <span className={s.walletNumber}>{wallet.walletAdress}</span>
            </span>
          </div>

          <div data-noclose className={s.signArea}>
            <span>{t("main.signature.message")}</span>
            <textarea
              data-noclose
              type="text"
              name="message"
              defaultValue={phrase}
              readOnly
            ></textarea>
          </div>
          <WalletSystemSelector
            zIndex="10"
            items={
              isMobile() || !window.ethereum
                ? walletSystems[1]
                : walletSystems
            }
            selectedItem={
              selectedWallet === "m" ? walletSystems[0] : walletSystems[1]
            }
            onChange={(item) => {
              setSelectedWallet(item.value === "metamask" ? "m" : "t");
            }}
          />

          <div data-noclose className={s.nextPrevSteps}>
            <button data-noclose onClick={backStep}>
              {t("main.back")}
            </button>

            {selectedWallet === "t" ? (
              <button
                data-noclose
                type="button"
                onClick={trustWalletNextStep}
                disabled={!phrase}
              >
                {t("main.next")}
              </button>
            ) : (
              <button data-noclose type="submit" disabled={!phrase}>
                {t("main.next")}
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

const Verification = ({ t, backStep, nextStep, connector }) => {
  const wallet = useSelector((state) => state.addWallet.addWallet);
  const signature = useSelector((state) => state.addWallet?.signature);
  const phrase = useSelector((state) => state.addWallet?.phrase);

  const alert = useContext(AlertContext);
  const dispatch = useDispatch();

  return (
    <div data-noclose className={s.signWrapper}>
      <span data-noclose className={s.signTitle}>
        {t("main.verification.title")}
      </span>

      <div data-noclose className={s.signContent}>
        <div data-noclose className={s.walletInput}>
          <span data-noclose>
            {t("main.verification.wallet")}
            <span data-noclose className={s.walletNumber}>
              {wallet.walletAdress}
            </span>
          </span>
        </div>

        <div data-noclose className={s.signArea}>
          <span data-noclose>{t("main.verification.message")}</span>
          <textarea
            data-noclose
            defaultValue={phrase}
            type="text"
            name="message"
            readOnly
          ></textarea>
        </div>

        <div data-noclose className={s.signatureWrapper}>
          <span data-noclose className={s.metaMaskTitle}>
            {t("main.verification.sign")}
          </span>
          <input data-noclose type={"text"} defaultValue={signature} readOnly />
        </div>

        <div data-noclose className={s.nextPrevSteps}>
          <button data-noclose onClick={backStep}>
            {t("main.back")}
          </button>
          <button
            data-noclose
            onClick={() => {
              dispatch(
                addConeWallet(
                  {
                    address: wallet.walletAdress,
                    signature,
                  },
                  alert,
                  t
                )
              );
              nextStep();
              connector?.killSession();
            }}
          >
            {t("main.next")}
          </button>
        </div>
      </div>
    </div>
  );
};
