import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { VscChromeClose } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertContext } from "../../../../App";
import { setSigupEmail } from "../../../../redux/reducers/signUpReducer";
import { SEND_VERIFY_CODE, SIGN_IN } from "../../../../redux/sagas/signUpSaga";
import s from "../../LoginPage.module.css";
import ErrorsBlock from "../LoginError/Errors";
import { IconWrapper } from "../../../../common-components/IconWrapper/IconWrapper";
import { useCallback } from "react";

const LoginForm = ({}) => {
  const dispatch = useDispatch();
  const alert = useContext(AlertContext);
  const errors = useSelector((state) => state.signUp.errors);
  const userNotVerified =
    !!errors && errors[0]?.message === "User not verified";

  const history = useNavigate();
  const { t } = useTranslation();

  const [codeForAuth, setCodeForAuth] = useState("");
  const [openAuthLogin, setOpenAuthLogin] = useState(false);
  const [type, setType] = useState("password");

  const submitForm = (values) => {
    dispatch({
      type: SIGN_IN,
      values: {
        ...values,
        email: values.email.toLocaleLowerCase(),
        lang: localStorage.getItem("i18nextLng"),
      },
      history,
      setOpenAuthLogin,
      codeForAuth,
      alert,
      t,
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: submitForm,
  });

  const onKeyPress = (event) => {
    if (event.key === "Enter" && openAuthLogin) {
      event.preventDefault();
      submitForm(formik.values);
    }
  };

  useEffect(() => {
    if (userNotVerified) {
      dispatch(setSigupEmail(formik.values.email.toLocaleLowerCase()));
      dispatch({
        type: SEND_VERIFY_CODE,
        email: formik.values.email.toLocaleLowerCase(),
        lang: localStorage.getItem("i18nextLng"),
        alert,
        t,
      });
      history("/signup/auth");
    }
  }, [errors]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {openAuthLogin ? (
        <div
          className={s.twoFactAuthWrapper}
          onClick={(e) => {
            if (!e.target.attributes.hasOwnProperty("data-2fa")) {
              setOpenAuthLogin(false);
              setCodeForAuth("");
            }
          }}
        >
          <div className={s.twoFactAuthContent} data-2fa={true}>
            <div
              onClick={() => {
                setOpenAuthLogin(false);
                setCodeForAuth("");
              }}
              className={s.closeBtn}
            >
              <VscChromeClose />
            </div>
            <span className={s.title} data-2fa={true}>
              {t("settings.authTitle")}
            </span>
            <div
              onKeyDown={onKeyPress}
              className={s.authContent}
              data-2fa={true}
            >
              <input
                type="text"
                value={codeForAuth}
                placeholder={t("settings.codePlaceholder")}
                onChange={(e) => {
                  if (e.target.value.length <= 6)
                    setCodeForAuth(e.target.value);
                }}
                data-2fa={true}
              />
              <div className={s.footer} data-2fa={true}>
                <button
                  data-2fa={true}
                  onClick={() => {
                    setOpenAuthLogin(false);
                    setCodeForAuth("");
                  }}
                >
                  {t("main.confirmDelete.cancel")}
                </button>
                <button
                  disabled={codeForAuth.length < 6}
                  type="submit"
                  data-2fa={true}
                >
                  {t("login.enterButton")}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className={s.inputBlock}>
        <label htmlFor="email">{t("login.email")}</label>
        <input
          onChange={(e) => {
            if (!e.target.value.includes(" ")) {
              formik.handleChange(e);
            }
          }}
          value={formik.values.email}
          type={"text"}
          id="email"
          name="email"
          placeholder={t("login.emailPlaceholder")}
          style={
            errors && !userNotVerified ? { border: "1px solid #ff6464" } : null
          }
        />
      </div>
      <div className={s.inputBlock}>
        <label htmlFor="password">{t("login.password")}</label>
        <input
          onChange={(e) => {
            if (!e.target.value.includes(" ")) {
              formik.handleChange(e);
            }
          }}
          className={` ${s.inputWithIcon}`}
          value={formik.values.password}
          type={type}
          id="password"
          name="password"
          placeholder={t("login.passwordPlaceholder")}
          style={
            errors && !userNotVerified ? { border: "1px solid #ff6464" } : null
          }
        />
        <div
          onClick={() =>
            type === "password" ? setType("text") : setType("password")
          }
          className={s.typeChangeBtn}
        >
          {type === "password" ? (
            <IconWrapper iconComponent={<FaEyeSlash />} />
          ) : (
            <IconWrapper iconComponent={<FaEye />} />
          )}
        </div>
      </div>

      {errors && !userNotVerified ? <ErrorsBlock errors={errors} /> : null}

      <div className={s.loginBtnWrapper}>
        <button
          disabled={!formik.values.email || !formik.values.password}
          className={s.loginBtn}
          type="submit"
        >
          {t("login.enterButton")}
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
