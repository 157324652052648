import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GET_REPLENISHMENTS_HISTORY } from "../../redux/sagas/historySaga";
import NoData from "../../assets/Component10.png";
import s from "./ReplenishmentHistory.module.css";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router-dom";
import { setReplenishmentsValue } from "../../redux/reducers/historyReducer";
import { VscChromeClose } from "react-icons/vsc";
import { useState } from "react";
import { AlertContext } from "../../App";
import { ReplenishmentHistoryItem } from "./ReplenishmentHistoryItem/ReplenishmentHistoryItem";
import { BuyConePopup } from "../Main/components/BuyConePopup/BuyConePopup";
import { useSocket } from "../../hooks/UseSocket";
import { handleHistoryFetch } from "../../utils/helpers";
import { getHashrate } from "../../redux/reducers/addWalletReducer";

export const ReplenishmentHistory = ({ openMenu }) => {
  const socket = useSocket();
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const dispatch = useDispatch();
  let history = useNavigate();
  let location = useParams();

  const isBuyConePopup = useSelector((state) => state?.buyCurrency?.isPopup);

  const pageData = useSelector(
    (state) => state.history.replenishmentsData?.data
  );
  const meta = useSelector((state) => state.history.replenishmentsData?.meta);

  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handlePageClick = (event) => {
    dispatch({
      type: GET_REPLENISHMENTS_HISTORY,
      page: event.selected + 1,
      alert,
      t,
    });
    history(`/replenishments/${event.selected + 1}`);
  };

  useEffect(() => {
    handleHistoryFetch(dispatch, location, history, alert, t);
    return () => {
      dispatch(setReplenishmentsValue({}));
    };
  }, []);

  useEffect(() => {
    if (pageData?.length && meta?.current_page === 1) {
      history(`/replenishments/${meta?.current_page}`);
    }
  }, [pageData]);

  useEffect(() => {
    if (socket?.repIdUpdate?.id) {
      handleHistoryFetch(dispatch, location, history, alert, t);
    }
    if (socket?.repIdUpdate?.status === "DONE") {
      dispatch(getHashrate());
    }
    socket.setRepIdUpdate(null);
  }, [socket]);

  return (
    <>
      {isBuyConePopup ? (
        <BuyConePopup replenishmentId={selectedItem.id} stepToStart={2} />
      ) : null}

      {pageData?.length ? (
        <div className={`${s.wrapper} ${openMenu ? s.wrapperFull : null}`}>
          <h2>{t("history.replenishmentHistory")}</h2>
          <div className={s.table}>
            <div className={s.header}>
              <div>{t("history.data")}</div>
              <div>{t("history.currency")}</div>
              <div>{t("history.repAmount")}</div>
              <div>{t("history.paymentAmount")}</div>
              <div>{t("history.hash")}</div>
              <div>{t(`history.status`)}</div>
            </div>
            {pageData
              ? pageData?.map((el, idx) => (
                  <ReplenishmentHistoryItem
                    setSelectedItem={setSelectedItem}
                    el={el}
                    key={`${el?.id}${idx}`}
                    openErrorPopup={openErrorPopup}
                    setOpenErrorPopup={setOpenErrorPopup}
                  />
                ))
              : null}

            {openErrorPopup ? (
              <div
                className={s.errorPopupWrapper}
                onClick={(e) => {
                  if (
                    e.target.nodeName === "DIV" &&
                    !e.target.attributes.hasOwnProperty("data-noclose")
                  ) {
                    setOpenErrorPopup(false);
                  }
                }}
              >
                <div data-noclose className={s.errorPopup}>
                  <div
                    onClick={() => setOpenErrorPopup(false)}
                    className={s.closeBtn}
                  >
                    <VscChromeClose />
                  </div>
                  {t("history.error")}
                </div>
              </div>
            ) : null}

            {meta?.last_page > 1 ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel={t("history.nextPage")}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={meta?.last_page}
                previousLabel={t("history.prevPage")}
                className={s.pages}
                pageClassName={s.pageNum}
                activeClassName={s.selected}
                previousLinkClassName={s.step}
                nextLinkClassName={s.step}
                breakClassName={s.break}
                forcePage={meta?.current_page - 1}
                renderOnZeroPageCount={null}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <>
          <div className={`${s.wrapper} ${openMenu ? s.wrapperFull : null}`}>
            <h2>{t("history.history")}</h2>
          </div>
          <div className={`${s.noData} ${openMenu ? s.noDataFull : null}`}>
            <img src={NoData} alt="no data" />
            <h4>{t("history.noDataTitle")}</h4>
            <span>{t("history.nodata")}</span>
          </div>
        </>
      )}
    </>
  );
};

export default History;
