import React, { useContext, useState } from "react";
import s from "./AddWalletButton.module.css";
import plus from "../../../../assets/plus.svg";
import { useTranslation } from "react-i18next";

export const AddWalletButton = ({
  currency = "btc",
  clickFunction,
  disabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={clickFunction}
      className={disabled ? `${s.cardWrapper} disabled` : s.cardWrapper}
    >
      <div className={s.cardtitle}>
        <img src={plus} alt="plus" />
        <span>
          {currency === "btc"
            ? t("main.createBtcWallet")
            : t("main.createConeWallet")}
        </span>
      </div>
      <div className={s.cardCurrency}>
        {currency === "btc" ? "BTC" : "CONE"}
      </div>
    </div>
  );
};
