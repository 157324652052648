import moment from "moment";
import s from "./BuyConeStepTwo.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { InfoBlock } from "./components/InfoBlock/InfoBlock";
import { useContext, useEffect } from "react";
import { ConfirmDelete } from "./components/ConfirmDeleting/ConfirmDelete";
import {
  DELETE_REPLENISHMENT,
  setBuyConePopup,
} from "../../../../../redux/reducers/buyConeReducer";
import { AlertContext } from "../../../../../App";
import {
  useLocation,
  useNavigate,
  useParams,
  useRoutes,
} from "react-router-dom";
import { handleHistoryFetch, reduceString } from "../../../../../utils/helpers";

export const BuyConeStepTwo = ({
  isDeleting,
  setIsDeleting,
  setStep,
  getDefaultValues,
}) => {
  const location = useLocation();
  let history = useNavigate();
  let params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const replenishmentInstance = useSelector(
    (store) => store.buyCurrency.replenishment
  );

  const alert = useContext(AlertContext);

  const onDelete = () => {
    dispatch({
      type: DELETE_REPLENISHMENT,
      replenishmentInput: {
        replenishmentId: replenishmentInstance.id,
      },
      setStep: () => {
        if (location.pathname.includes("replenishments")) {
          handleHistoryFetch(dispatch, params, history, alert, t);
        }
        dispatch(setBuyConePopup({ isPopup: false }));
        setIsDeleting(false);
      },
      alert,
      t,
    });
    getDefaultValues();
  };

  return (
    <div data-noclose className={s.content}>
      {isDeleting ? (
        <ConfirmDelete
          isDeleting={isDeleting}
          closePopup={() => setIsDeleting(false)}
          onDelete={onDelete}
        />
      ) : null}
      <InfoBlock
        blockTitle={""}
        infoItems={[
          {
            infoItemTitle: `${t("buyConePopup.status")}:`,
            infoItemText: t(`buyConePopup.${replenishmentInstance.status}`),
          },
          {
            infoItemTitle: `${t("buyConePopup.expiresAt")}:`,
            infoItemText: `${moment(replenishmentInstance?.expires_at).format(
              "DD.MM.YYYY, HH:mm:ss"
            )}`,
          },
        ]}
      />
      <InfoBlock
        blockTitle={t("buyConePopup.send")}
        infoItems={[
          {
            infoItemTitle: `${t("buyConePopup.replenishmentAddress")}:`,
            infoItemText: reduceString(
              replenishmentInstance?.replenishment_address
            ),
            copy: true,
            copyValue: replenishmentInstance.replenishment_address
              ? replenishmentInstance.replenishment_address
              : "",
          },
          {
            infoItemTitle: `${t("buyConePopup.replenishmentAmount")}:`,
            infoItemText: `${replenishmentInstance.replenishment_amount} ${replenishmentInstance.currency}`,
          },
          {
            infoItemTitle: `${t("buyConePopup.received")}:`,
            infoItemText: `${replenishmentInstance.partially_received || 0} ${
              replenishmentInstance.currency
            }`,
          },
        ]}
      />
      <InfoBlock
        blockTitle={t("buyConePopup.receive")}
        infoItems={[
          {
            infoItemTitle: t("buyConePopup.paymentAddress"),
            infoItemText: reduceString(replenishmentInstance?.payment_address),
          },
          {
            infoItemTitle: t("buyConePopup.sum"),
            infoItemText: `${replenishmentInstance.payment_amount || 0} CONE`,
          },
        ]}
        isSeparator={false}
      />
    </div>
  );
};
