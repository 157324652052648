import React from "react";
import s from "./CurrencyDynamic.module.css";

export const CurrencyDynamic = ({
  padding = 0,
  image = null,
  title,
  amount,
  percent,
  chartValues = null,
}) => {
  return (
    <div style={{ paddingLeft: `${padding}px` }} className={s.content}>
      <div className={s.currencyAmount}>
        {image ? (
          <div className={s.cardTitleWithImage}>
            <img src={image} alt="dynamic_image" />
            {title}
          </div>
        ) : (
          <div className={s.cardTitle}>{title}</div>
        )}

        <div>$ {amount}</div>
        <div
          style={percent >= 0 ? { color: "#2AC769" } : { color: "#FF6464" }}
          className={s.percentValue}
        >
          {percent >= 0 ? "" : "-"}
          {percent} %
        </div>
      </div>
    </div>
  );
};
