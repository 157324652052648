import { useContext, useEffect } from "react";
import { AlertContext } from "../../../App";
import {
  copyToClipboard,
  getColorStatus,
  getStatusTitle,
  reduceString,
} from "../../../utils/helpers";
import s from "./ReplenishmentHistoryItem.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { setBuyConePopup } from "../../../redux/reducers/buyConeReducer";

export const ReplenishmentHistoryItem = ({ el, setSelectedItem }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const isReplenishment = useSelector((store) => store.history.isReplenishment);
  const error = el?.status === "Ошибка" || el?.status === "Error";

  useEffect(() => {}, [isReplenishment]);

  const copyTx = (e) => {
    if (el?.payment_tx) {
      copyToClipboard(e, t, alert, el?.payment_tx);
    }
  };

  return (
    <div
      className={s.rowReplenishment}
      onClick={(e) => {
        if (!e.target.attributes.hasOwnProperty("data-noopen")) {
          setSelectedItem(el);
          dispatch(setBuyConePopup({ isPopup: true, walletId: "" }));
        }
      }}
      style={error ? { cursor: "pointer" } : null}
    >
      <div>{`${el?.processing_date?.split("T")[0].split("-")[2]}.${
        el?.processing_date?.split("T")[0].split("-")[1]
      }.${el?.processing_date?.split("T")[0].split("-")[0]}`}</div>
      <div data-title={el?.currency} className={s.currency}>
        {el?.currency}
      </div>
      <div data-title={el?.replenishment_amount}>
        <div className={s.ellipsis}>{el?.replenishment_amount}</div>
      </div>
      <div data-title={el?.payment_amount}>{`${el?.payment_amount}`}</div>
      <div data-noopen onClick={(e) => copyTx(e)} data-title={el?.payment_tx}>
        <div data-noopen className={s.ellipsis}>
          {reduceString(el?.payment_tx)}
        </div>
      </div>
      <div
        data-noopen
        data-title={getStatusTitle(el?.status, t)}
        style={{ color: getColorStatus(el?.status) }}
      >
        {t(`buyConePopup.${el?.status}`)}
      </div>
    </div>
  );
};
