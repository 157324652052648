import { useTranslation } from "react-i18next";
import s from "./BuyConeStepThree.module.css";
import { WalletSystemSelector } from "../../../../../common-components/WalletSystemSelector/WalletSystemSelector";
import { walletSystems } from "../../../../../utils/constanst";
import { useEffect } from "react";
import { isMobile } from "../../../../../utils/helpers";

export const BuyConeStepThree = ({ form, setForm, setStep }) => {
  const { t } = useTranslation();
  const setWallet = (item) => {
    setForm({ walletSystem: item });
  };

  useEffect(() => {
    if (isMobile() || !window.ethereum) {
      setForm({ walletSystem: walletSystems[1] });
    }
  }, []);

  return (
    <div data-noclose className={s.content}>
      <div data-noclose className={s.selectLabel}>
        {t("buyConePopup.payVia")}:
      </div>
      <WalletSystemSelector
        items={
          isMobile() || !window.ethereum ? [walletSystems[1]] : walletSystems
        }
        selectedItem={form.walletSystem}
        onChange={setWallet}
      />
    </div>
  );
};
