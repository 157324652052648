import { addWalletWatcher } from "./addWalletSaga";
import { signUpWatcher } from "./signUpSaga";
import { settingsWatcher } from "./settingsSaga";
import { historyWatcher } from "./historySaga";
import { buyCurrencyWatcher } from "./buyCurrencySaga";

export default [
  signUpWatcher,
  addWalletWatcher,
  settingsWatcher,
  historyWatcher,
  buyCurrencyWatcher,
];
