import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AlertContext } from "../../../../../App";
import { Selector } from "./components/Selector/Selector";
import {
  GET_EXCHANGE_RATE,
  setLoading,
} from "../../../../../redux/reducers/buyConeReducer";
import s from "./BuyConeStepOne.module.css";
import { WalletSelector } from "./components/WalletSelector/WalletSelector";
import { VscArrowDown } from "react-icons/vsc";
import { useEffect } from "react";
import cone from "../../../../../assets/cone.png";

export const BuyConeStepOne = ({ setForm, form, replenishmentId, close }) => {
  const [calculationTimeOut, setCalculationTimeOut] = useState(null);

  const {
    fee,
    currenciesToPay,
    walletId,
    coneWallets,
    exchangeRate,
    exchangeTotal,
    inputs,
  } = useSelector((store) => store.buyCurrency);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const alert = useContext(AlertContext);

  const getDecimalRegExp = (maxDecimal) => {
    return new RegExp(`^\\d{0,18}(\\.\\d{0,${maxDecimal}})?$`);
  };

  const handleConeAmount = (e, type) => {
    if (e.target.value.includes(",")) {
      e.target.value = e.target.value.replace(",", ".");
    }
    if (calculationTimeOut) {
      clearTimeout(calculationTimeOut);
    }
    if (+e.target.value < 0) {
      if (type) {
        setForm({ coneAmount: 0 });
      } else {
        setForm({ currencyAmount: 0 });
      }
    } else {
      if (
        !type &&
        getDecimalRegExp(form.currencyToPay.decimalLimit).test(e.target.value)
      ) {
        setForm({ currencyAmount: e.target.value });
      }
      if (type && getDecimalRegExp(18).test(e.target.value)) {
        setForm({ coneAmount: e.target.value });
      }
    }
    dispatch(setLoading(true));
    const timeout = setTimeout(() => {
      dispatch(
        {
          type: GET_EXCHANGE_RATE,
          currency: form.currencyToPay.value,
          amount: e.target.value <= 0 ? `1` : e.target.value,
          alert,
          t,
          reverse: !!type,
          minCone: form.currencyToPay.minCone,
        },
        []
      );
    }, 3000);
    setCalculationTimeOut(timeout);
  };

  const handleCurrencyChange = (item) => {
    setForm({ currencyToPay: item });
  };

  useEffect(() => {
    if (exchangeTotal) {
      setForm({ currencyAmount: inputs.currency });
    }
  }, [inputs]);

  useEffect(() => {
    if (!currenciesToPay.length && !replenishmentId) {
      close();
      alert("error", t("errors.noCurrencies"));
    }
  }, []);

  return (
    <div data-noclose className={s.content}>
      <div data-noclose className={s.rate}>
        <div data-noclose className={s.rateInfo}>
          1 CONE ≈ {exchangeRate} {form.currencyToPay?.name}
        </div>
      </div>
      <div data-noclose className={s.inputsWrapper}>
        <div data-noclose className={s.inputItem}>
          <div data-noclose className={s.inputTitle}>
            {t("buyConePopup.get")}
          </div>
          <div data-noclose className={s.inputHandler}>
            <input
              inputMode="decimal"
              data-noclose
              value={form.coneAmount || ""}
              onChange={(e) => handleConeAmount(e, 1)}
              className={s.input}
              type="text"
            />
            <div data-noclose className={s.currency}>
              <img className={s.coneImage} src={cone} alt="CONE" />
              CONE
            </div>
          </div>
        </div>
        <div data-noclose className={s.arrow}>
          <VscArrowDown size={25} />
        </div>
        <div data-noclose className={s.inputItem}>
          <div data-noclose className={s.inputTitle}>
            {t("buyConePopup.sell")}
          </div>
          <div data-noclose className={s.inputHandler}>
            <input
              readOnly
              inputMode="decimal"
              value={form.currencyAmount || ""}
              onChange={(e) => handleConeAmount(e, 0)}
              className={`${s.input} ${s.currencyInput}`}
              type="text"
            />
            <div data-noclose className={s.currencyToPay}>
              <Selector
                zIndex={2}
                items={currenciesToPay}
                selectedItem={form.currencyToPay}
                onChange={(item) => {
                  handleCurrencyChange(item);
                  dispatch(
                    {
                      type: GET_EXCHANGE_RATE,
                      currency: item.value,
                      amount: form.coneAmount || form.currencyToPay.minCone,
                      alert,
                      t,
                      reverse: true,
                      minCone: form.currencyToPay.minCone,
                    },
                    []
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div data-noclose className={s.inputItem}>
        <div data-noclose className={s.additionalInfo}>
          {t("buyConePopup.includes")}: <br />
          <div data-noclose className={s.fee}>
            <span style={{ marginRight: "5px" }}>
              {t("buyConePopup.serviceFee")}
            </span>
            <span>
              {fee.currency} {form.currencyToPay?.name} ≈ {fee.usd} USD
            </span>
          </div>
        </div>
      </div>
      {walletId ? null : (
        <div data-noclose className={s.inputItem}>
          <div data-noclose className={s.inputTitle}>
            {t("buyConePopup.selectWallet")}
          </div>
          <div data-noclose className={s.inputHandler}>
            <WalletSelector
              disabled={!!walletId}
              zIndex={1}
              items={coneWallets}
              selectedItem={form.coneWalletId}
              onChange={(item) => {
                setForm({ coneWalletId: item });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
