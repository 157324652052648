import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ErrorImg from "../../../../assets/err.png";
import s from "../../LoginPage.module.css";

const ErrorsBlock = ({ errors }) => {
  const { t } = useTranslation();
  let userNotFound = errors && errors[0]?.message === "User not found";

  return (
    <div className={s.errorWrapper}>
      <img src={ErrorImg} alt={"error_img"} />
      <div className={s.errorContent}>
        <span className={s.errorTitle}>{t("errors.login")}</span>
        {userNotFound ? (
          <span>
            {t("errors.notRegistered")}
            <Link to={"/signup"}>{t("errors.signUpLink")}</Link>
          </span>
        ) : (
          <div>
            <span>{t("errors.check")}</span>
            <div className={s.addErrorContent}>
              <li>{t("errors.caps")}</li>
              <li>{t("errors.ruengChange")}</li>
              <span>{t("errors.rueng")}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorsBlock;
