import React, { useContext, useState } from "react";
import s from "./Wallet.module.css";
import DeleteImg from "../../../../assets/deleteImg.png";
import DeleteImgWh from "../../../../assets/deleteImgWh.png";
import { useTranslation } from "react-i18next";
import { VscChromeClose } from "react-icons/vsc";
import ConeLogo from "../../../../assets/cone.png";
import BtcLogo from "../../../../assets/bitcoin.png";
import { useDispatch, useSelector } from "react-redux";
import { deleteWallets } from "../../../../redux/reducers/addWalletReducer";
import { AlertContext } from "../../../../App";
import { reduceString } from "../../../../utils/helpers";

const Wallet = ({ data, openBuyModal }) => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [isReplenishmentError, setIsReplenishmentError] = useState(false);

  const dispatch = useDispatch();
  const { isBuyAvailable } = useSelector((store) => store.settings);

  const { type } = data;
  const { address } = data;
  const { currency } = data;

  const showReplenishmentError = (value) => {
    if (!isBuyAvailable) {
      setIsReplenishmentError(value);
    }
  };

  return (
    <div
      className={currency === "CONE" ? s.walletWrapperCone : s.walletWrapperBtc}
    >
      {isReplenishmentError ? (
        <div className={s.error}>{t("statusesTitle.finishReplenishment")}</div>
      ) : null}
      {openDeleteConfirm ? (
        <div
          className={s.confirmDeleteWrapper}
          onClick={(e) => {
            if (!e.target.attributes.hasOwnProperty("data-noclose")) {
              setOpenDeleteConfirm(false);
            }
          }}
        >
          <div data-noclose className={s.confirmDeleteContent}>
            <div
              data-noclose
              onClick={() => setOpenDeleteConfirm(false)}
              className={s.closeBtn}
            >
              <VscChromeClose data-noclose />
            </div>
            <span data-noclose className={s.title}>
              {t("main.confirmDelete.title")}
            </span>
            <div data-noclose className={s.content}>
              <span data-noclose>
                {t("main.confirmDelete.content")}
                <br />
                <span className={`${s.bold} ${s.green}`}>
                  {reduceString(address)}
                </span>
              </span>
            </div>
            <div data-noclose className={s.footer}>
              <button onClick={() => setOpenDeleteConfirm(false)}>
                {t("main.confirmDelete.cancel")}
              </button>
              <button
                onClick={() => {
                  dispatch(deleteWallets({ ...data, alert, t }));
                  setOpenDeleteConfirm(false);
                }}
              >
                {t("main.confirmDelete.confirm")}
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <div className={s.header}>
        <div className={s.manipulate}>
          <div onClick={() => setOpenDeleteConfirm(true)} className={s.delete}>
            <img
              alt="delete"
              src={currency === "CONE" ? DeleteImg : DeleteImgWh}
            />
          </div>
        </div>
        <div className={s.balance}>
          <img
            alt="currency_logo"
            src={currency === "CONE" ? ConeLogo : BtcLogo}
          />
          <span
            data-currency={currency}
            data-maxwidth={data?.balance?.length > 18}
          >
            {currency === "CONE" ? data.balance : null}
          </span>
          <span>{data.currency}</span>
        </div>
      </div>

      <div className={s.footer}>
        <div className={s.address}>{t("main.wallet.address")}</div>
        <div className={s.adressValue} data-coin={currency}>
          {reduceString(data.address)}
          {currency === "CONE" ? (
            <div
              onMouseEnter={() => showReplenishmentError(true)}
              onMouseLeave={() => showReplenishmentError(false)}
              onClick={() => {
                if (isBuyAvailable) openBuyModal();
              }}
              className={
                isBuyAvailable ? `${s.buyBtn}` : `${s.buyBtn} ${s.disabled}`
              }
            >
              {t("main.wallet.buy")}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Wallet;
