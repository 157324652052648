import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaBars } from "react-icons/fa";
import { RiChatHistoryLine, RiDashboardLine } from "react-icons/ri";
import { RiHistoryLine } from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import LogoutImg from "../../assets/logout.png";
import SupportImg from "../../assets/support.svg";
import s from "./Dashboard.module.css";
import { useTranslation } from "react-i18next";
import { logOut } from "../../redux/reducers/signUpReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  getHashrate,
  logOutWallets,
} from "../../redux/reducers/addWalletReducer";
import {
  GET_SETTING_VALUES,
  setDashboardNav,
} from "../../redux/reducers/settingsReducer";
import { setInitialBuyCone } from "../../redux/reducers/buyConeReducer";
import { AttentionMarker } from "../../common-components/AttentionMarker/AttentionMarker";

const Dashboard = ({ setOpenMobileTotal, open, setOpen }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { isBuyAvailable } = useSelector((store) => store.settings);

  const [resizeSmall, setResizeSmall] = useState(true);
  const [resizeBig, setResizeBig] = useState(false);

  let email = JSON.parse(window.localStorage.getItem("e"));

  const openMenu = () => {
    dispatch(setDashboardNav(!open));
    setOpen(!open);
  };

  useEffect(() => {
    const listenResizeEvent = () => {
      window.innerWidth <= 700 ? setResizeSmall(true) : setResizeSmall(false);
      window.innerWidth > 700 ? setResizeBig(true) : setResizeBig(false);
    };
    listenResizeEvent();
    window.addEventListener("resize", listenResizeEvent, { passive: true });
    return () => window.removeEventListener("resize", listenResizeEvent);
  }, []);

  useEffect(() => {
    if (document.cookie?.indexOf("bearer") === -1) {
      history("/login");
    } else if (location.pathname === "/") {
      history("/dashboard");
    }
    dispatch({ type: GET_SETTING_VALUES });
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      history("/dashboard");
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getHashrate());
  }, []);

  useEffect(() => {
    if (open) {
      setOpenMobileTotal(false);
    }
  }, [open]);

  const logOutHandle = () => {
    document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    window.localStorage.removeItem("e");
    dispatch(setInitialBuyCone());
    dispatch(logOut());
    dispatch(logOutWallets());
    history("/login");
  };

  return (
    <>
      <div className={s.wrapper}>
        <input
          onChange={() => openMenu()}
          checked={open}
          type="checkbox"
          id="nav-toggle"
          hidden
        />

        {open && resizeSmall ? (
          <div onClick={() => openMenu()} className={s.hoverAll}></div>
        ) : null}
        <nav className={s.nav} data-ismobileclose={!open && resizeSmall}>
          <label htmlFor="nav-toggle" className={s.navToggle}>
            {open ? (
              <>
                <FaArrowLeft />
                <span className={s.email}>
                  {email ? email.toLocaleLowerCase() : null}
                </span>
              </>
            ) : (
              <FaBars />
            )}
          </label>

          <div className={s.emailBlock} data-open={open}></div>

          <ul style={!open ? { visibility: "hidden" } : null}>
            <li>
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  isActive ? s.active + " " + s.menuLink : s.menuLink
                }
                onClick={() => (resizeSmall ? openMenu() : null)}
              >
                <RiDashboardLine
                  className={s.image}
                  style={{ transform: "scale(1, -1)" }}
                />
                <span>{t("dashboard.main")}</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/analytics"
                className={({ isActive }) =>
                  isActive ? s.active + " " + s.menuLink : s.menuLink
                }
                onClick={() => (resizeSmall ? openMenu() : null)}
              >
                <BsGraphUp />
                <span>{t("dashboard.analytics")}</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/history"
                className={({ isActive }) =>
                  isActive ? s.active + " " + s.menuLink : s.menuLink
                }
                onClick={() => (resizeSmall ? openMenu() : null)}
              >
                <RiHistoryLine className={s.image} />
                <span>{t("dashboard.history")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/replenishments"
                className={({ isActive }) =>
                  isActive ? s.active + " " + s.menuLink : s.menuLink
                }
                onClick={() => (resizeSmall ? openMenu() : null)}
              >
                <RiChatHistoryLine className={s.image} />
                <span>{t("dashboard.replenishments")}</span>
                {!isBuyAvailable && <AttentionMarker right={-10} />}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings"
                className={({ isActive }) =>
                  isActive ? s.active + " " + s.menuLink : s.menuLink
                }
                onClick={() => (resizeSmall ? openMenu() : null)}
              >
                <FiSettings className={s.image} />
                <span>{t("dashboard.settings")}</span>
              </NavLink>
            </li>
          </ul>

          {resizeBig ? (
            <ul
              className={s.shortMenu}
              style={open ? { visibility: "hidden" } : null}
            >
              <li>
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) =>
                    isActive ? s.active + " " + s.menuLink : s.menuLink
                  }
                >
                  <RiDashboardLine
                    className={s.image}
                    style={{ transform: "scale(1, -1)" }}
                  />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/history"
                  className={({ isActive }) =>
                    isActive ? s.active + " " + s.menuLink : s.menuLink
                  }
                >
                  <RiHistoryLine className={s.image} />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/replenishments"
                  className={({ isActive }) =>
                    isActive ? s.active + " " + s.menuLink : s.menuLink
                  }
                >
                  <RiChatHistoryLine className={s.image} />
                  {!isBuyAvailable && <AttentionMarker right={-8} />}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/settings"
                  className={({ isActive }) =>
                    isActive ? s.active + " " + s.menuLink : s.menuLink
                  }
                >
                  <FiSettings className={s.image} />
                </NavLink>
              </li>
              <li className={s.bottomWrapperMin}>
                <a
                  href="mailto:investor@cryptoone.farm"
                  className={`${s.bottomItem} ${s.shortLink}`}
                >
                  <img className={s.image} src={SupportImg} alt="support" />
                </a>
                <div
                  className={`${s.bottomItem} ${s.shortLink} ${s.logout}`}
                  onClick={logOutHandle}
                >
                  <img className={s.image} src={LogoutImg} alt="logout" />
                </div>
              </li>
            </ul>
          ) : null}

          {open && (
            <div className={s.bottomWrapper}>
              <a href="mailto:investor@cryptoone.farm" className={s.bottomItem}>
                <img className={s.image} src={SupportImg} alt="support" />
                <span>{t("dashboard.support")}</span>
              </a>
              <div
                className={`${s.bottomItem} ${s.logout}`}
                onClick={logOutHandle}
              >
                <img
                  src={LogoutImg}
                  className={`${s.logout} ${s.image}`}
                  alt="logout"
                />
                <span>{t("dashboard.exit")}</span>
              </div>
            </div>
          )}
        </nav>

        <Outlet />
      </div>
    </>
  );
};

export default Dashboard;
