import React, { useEffect, useState } from "react";
import Logo from "./Logo/Logo";
import Total from "../../assets/Wallet.png";
import s from "./Header.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GET_CURRENCIES_TO_PAY,
  setBuyConePopup,
} from "../../redux/reducers/buyConeReducer";
import { getAuth } from "../../utils/helpers";

const Header = ({ openMobileTotal, setOpenMobileTotal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hashRate = useSelector((state) => state.addWallet.hashrate);
  const coneSum = useSelector((state) => state.addWallet.CONESum);
  const { isDashboardNav } = useSelector((state) => state.settings);
  const totalIncome = useSelector((state) => state.addWallet.totalIncome);
  const { isBuyAvailable } = useSelector((store) => store.settings);

  const [resize, setResize] = useState(false);
  const [shortHeader, setShortHeader] = useState(true);

  let location = useLocation();
  const navigate = useNavigate();

  const openBuyConePopup = () => {
    if (isBuyAvailable) {
      navigate("/dashboard");
      dispatch(setBuyConePopup({ isPopup: true, walletId: "" }));
    }
  };

  useEffect(() => {
    setShortHeader(
      location.pathname !== "/login" &&
        location.pathname !== "/signup" &&
        location.pathname !== "/resetPassword" &&
        location.pathname !== "/signup/auth"
    );
  }, [location]);

  useEffect(() => {
    if (getAuth()) {
      dispatch({ type: GET_CURRENCIES_TO_PAY, t, alert });
    }
    const listenResizeEvent = () => {
      window.innerWidth <= 1190 ? setResize(true) : setResize(false);
    };
    listenResizeEvent();
    window.addEventListener("resize", listenResizeEvent, { passive: true });
    return () => window.removeEventListener("resize", listenResizeEvent);
  }, []);

  return (
    <header
      style={
        isDashboardNav
          ? !shortHeader
            ? { width: "100%" }
            : {}
          : { width: "calc(100% - 60px)", transition: "0.3s" }
      }
      className={shortHeader ? `${s.wrapper}` : `${s.wrapper} ${s.full}`}
    >
      <Logo />

      {shortHeader && (hashRate || coneSum || totalIncome) ? (
        <>
          {Boolean(coneSum) && (
            <div className={s.allConeWrapper}>
              <div className={s.allCone}>
                <span>{coneSum} CONE</span>
                <span>{t("available")}</span>
              </div>
            </div>
          )}
          {Boolean(hashRate) && (
            <div className={s.allConeWrapper}>
              <div className={s.allCone}>
                <span>{hashRate} TH/s</span>
                <span>{t("hash")}</span>
              </div>
            </div>
          )}
          {Boolean(totalIncome) && (
            <div className={s.allConeWrapper}>
              <div className={s.allCone}>
                <span>{totalIncome.toFixed(8)} BTC</span>
                <span>{t("totalIncome")}</span>
              </div>
            </div>
          )}
          <div
            data-title={
              !isBuyAvailable ? t("statusesTitle.finishReplenishment") : null
            }
            onClick={() => openBuyConePopup()}
            className={
              isBuyAvailable ? `${s.buyBtn}` : `${s.buyBtn} ${s.disabled}`
            }
          >
            {t("buyConePopup.stepOne")}
          </div>
        </>
      ) : null}
      {(hashRate || coneSum || totalIncome) && resize ? (
        <>
          <img
            onClick={() => setOpenMobileTotal(!openMobileTotal)}
            className={s.total}
            src={Total}
            alt="total"
            style={{ maxWidth: "24px", maxHeight: "24px" }}
          />

          {openMobileTotal ? (
            <div className={s.totalContentWrapper}>
              {Boolean(coneSum) && (
                <div className={s.totalContent}>
                  <span>{coneSum} CONE</span>
                  <span>{t("available")}</span>
                </div>
              )}
              {Boolean(hashRate) && (
                <div className={s.totalContent}>
                  <span>{hashRate} TH/s</span>
                  <span>{t("hash")}</span>
                </div>
              )}
              {Boolean(totalIncome) && (
                <div className={s.totalContent}>
                  <span>{totalIncome.toFixed(8)} BTC</span>
                  <span>{t("totalIncome")}</span>
                </div>
              )}
              <div
                data-title={
                  !isBuyAvailable
                    ? t("statusesTitle.finishReplenishment")
                    : null
                }
                className={
                  isBuyAvailable
                    ? `${s.buyBtn} ${s.visible}`
                    : `${s.buyBtn} ${s.visible} ${s.disabled}`
                }
                onClick={() => openBuyConePopup()}
              >
                {t("buyConePopup.stepOne")}
              </div>
            </div>
          ) : null}
          {openMobileTotal ? (
            <div
              onClick={() => setOpenMobileTotal(!openMobileTotal)}
              className={s.blur}
            ></div>
          ) : null}
        </>
      ) : null}
    </header>
  );
};

export default Header;
