import { useSelector } from "react-redux";
import s from "./InfoBlock.module.css";
import { useTranslation } from "react-i18next";
import { VscCopy } from "react-icons/vsc";
import { useContext } from "react";
import { AlertContext } from "../../../../../../../App";
import { copyToClipboard } from "../../../../../../../utils/helpers";

export const InfoBlock = ({
  blockTitle,
  isSeparator = true,
  infoItems = [],
}) => {
  const alert = useContext(AlertContext);
  const { t } = useTranslation();
  const replenishmentInstance = useSelector(
    (store) => store.buyCurrency.replenishment
  );

  return (
    <div data-noclose className={s.infoBlock}>
      {blockTitle ? (
        <p data-noclose className={s.infoBlockTitle}>
          {blockTitle}
        </p>
      ) : null}
      {infoItems.map((el, index) => {
        return (
          <div
            key={index + el.infoItemText}
            data-noclose
            className={s.infoItem}
          >
            <span
              data-noclose
              key={index + el.infoItemTitle}
              className={s.infoItemTitle}
            >
              {el.infoItemTitle}
            </span>
            <span
              data-noclose
              key={index + el.infoItemText}
              className={s.infoItemText}
            >
              {el.infoItemText}
            </span>
            {el?.copy ? (
              <div
                data-noclose
                onClick={(e) =>
                  copyToClipboard(e, t, alert, el.copyValue ? el.copyValue : "")
                }
                className={s.copyHandler}
              >
                <VscCopy data-noclose />
              </div>
            ) : null}
          </div>
        );
      })}
      {isSeparator ? <div data-noclose className={s.separator}></div> : null}
    </div>
  );
};
