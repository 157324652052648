import {
  CREATE_REPLENISHMENT,
  setBuyConePopup,
} from "../../../../../../redux/reducers/buyConeReducer";
import s from "./BuyConeNavigationButtons.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AlertContext } from "../../../../../../App";
import bin from "../../../../../../assets/bin.svg";
import {
  createTransactionMetamask,
  createTransactionWalletConnect,
} from "../../../../../../utils/helpers";
import { BsGear } from "react-icons/bs";
import { availableCurrencies } from "../../../../../../utils/constanst";

export const BuyConeNavigationButtons = ({
  step,
  setStep,
  form,
  close,
  setIsDeleting,
}) => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const { replenishment } = useSelector((store) => store.buyCurrency);
  const isLoaing = useSelector((store) => store.buyCurrency.isLoading);

  const dispatch = useDispatch();

  const handleStep = async (direction) => {
    let nextStepValue = step;
    if (direction) {
      if (nextStepValue === 1) {
        dispatch(
          {
            type: CREATE_REPLENISHMENT,
            replenishmentInput: {
              currency: form.currencyToPay?.value || form.network?.value,
              amount: form.coneAmount || form.currencyToPay.minCone || `1`,
              walletId: form.coneWalletId.value || "",
            },
            coneValues: {
              minCone: form.currencyToPay.minCone,
            },
            alert,
            t,
          },
          []
        );
        return;
      }
      if (nextStepValue === 3) {
        if (form.walletSystem.value === "metamask") {
          createTransactionMetamask(replenishment, close, alert, t);
        } else if (form.walletSystem.value === "walletConnect") {
          createTransactionWalletConnect(replenishment, close, alert, t);
        }
      } else {
        setStep(nextStepValue + 1);
      }
    } else {
      if (nextStepValue === 1) {
        dispatch(setBuyConePopup({ isPopup: false }));
      }
      if (nextStepValue === 2) {
      } else {
        nextStepValue -= 1;
        setStep(nextStepValue);
        return;
      }
    }
  };

  const getNextStepText = () => {
    if (step === 2) {
      if (replenishment) {
        return t("buyConePopup.pay");
      }
    }
    if (step === 3) {
      return t("buyConePopup.pay");
    }
    return t("main.addWallet.continue");
  };

  const getPrevStepText = () => {
    if (step === 1) {
      return t("main.addWallet.cancel");
    }
    if (step === 2) {
      return t("buyConePopup.deleteReplenishment");
    }
    if (step === 3) {
      return t("main.back");
    }
  };

  return (
    <div
      data-noclose
      className={
        step === 3 && replenishment.currency === availableCurrencies[2].name
          ? `${s.btnWrapper} ${s.centerWrapper}`
          : s.btnWrapper
      }
    >
      {step === 1 ? (
        <>
          <div
            onClick={() => handleStep(false)}
            data-noclose
            className={s.remove}
          >
            {getPrevStepText()}
          </div>
          <div
            onClick={() => handleStep(true)}
            data-noclose
            className={`${s.continue} ${isLoaing ? s.disabled : ""}`}
          >
            {!isLoaing ? (
              getNextStepText()
            ) : (
              <BsGear className={s.gearIcon} height={25} width={25} />
            )}
          </div>
        </>
      ) : null}
      {step === 2 && replenishment?.status === "CREATED" ? (
        <div data-noclose className={s.stepTwoBlock}>
          <div
            data-noclose
            className={s.deleteBtn}
            onClick={() => setIsDeleting(true)}
          >
            <img
              data-noclose
              src={bin}
              alt="bin_image"
              width={14}
              height={18}
            />
          </div>
          <div
            data-noclose
            onClick={() => handleStep(true)}
            className={`${s.continue} ${isLoaing ? s.disabled : ""}`}
          >
            {getNextStepText()}
          </div>
        </div>
      ) : null}
      {step === 3 && replenishment.currency !== availableCurrencies[2].name ? (
        <>
          <div
            onClick={() => handleStep(false)}
            data-noclose
            className={s.remove}
          >
            {getPrevStepText()}
          </div>
          <div
            onClick={() => handleStep(true)}
            data-noclose
            className={`${s.continue} ${isLoaing ? s.disabled : ""}`}
          >
            {getNextStepText()}
          </div>
        </>
      ) : null}
      {step === 3 && replenishment.currency === availableCurrencies[2].name ? (
        <div className={s.centerWrapper}>
          <div
            onClick={() => handleStep(false)}
            data-noclose
            className={s.remove}
          >
            {getPrevStepText()}
          </div>
        </div>
      ) : null}
    </div>
  );
};
