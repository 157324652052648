import React, { useContext, useEffect, useState } from "react";
import { VscChromeClose } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import DoneCircle from "../../../../assets/tick-circle.png";
import ErrorCircle from "../../../../assets/close-circle.png";
import s from "./AddWallet.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addBtcWallet,
  addWalletFail,
  getWallets,
  setSignaturePhraseValue,
  setSignatureValue,
  setWalletValue,
} from "../../../../redux/reducers/addWalletReducer";
import { AlertContext } from "../../../../App";
import { AddWalletContent } from "./AddWalletContent";
// import bitcore from "bitcore-lib";

const AddWalletBtc = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const alert = useContext(AlertContext);
  const [walletAdress, setWalletAdress] = useState("");

  const error = useSelector((state) => state.addWallet?.error);

  const [activeStep, setActiveStep] = useState(1);

  const activeClasses = (style, step) => {
    if (activeStep === step) {
      return style.activeStep;
    } else if (step < activeStep) {
      return s.completedStep;
    } else {
      return style.step;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setSignatureValue(""));
      dispatch(setSignaturePhraseValue({ phrase: "" }));
      dispatch(setWalletValue({ walletAdress: "", blockchain: "" }));
      dispatch(addWalletFail({}));
    };
  }, []);

  return (
    <div
      className={s.wrapper}
      onClick={(e) => {
        if (!e.target.attributes.hasOwnProperty("data-noclose")) {
          onClose();
        }
      }}
    >
      {error[0]?.message === "Wallet already exist in the system" ? (
        <div data-noclose className={s.consfirmAdding}>
          <h4 data-noclose>{t("main.addWallet.walletAdding")}</h4>
          <span data-noclose>{t("main.addWallet.walletExist")}</span>
          <div data-noclose className={s.btnGroup}>
            <button data-noclose onClick={() => onClose()}>
              {t("main.addWallet.cancel")}
            </button>
            <button
              data-noclose
              onClick={() => {
                dispatch(
                  addBtcWallet(
                    {
                      address: walletAdress,
                      confirm: true,
                      nextStep: setActiveStep,
                    },
                    alert,
                    t
                  )
                );
              }}
            >
              {t("main.addWallet.continue")}
            </button>
          </div>
        </div>
      ) : null}
      <div
        className={s.content}
        data-noclose
        style={
          error[0]?.message === "Wallet already exist in the system"
            ? { display: "none" }
            : null
        }
      >
        <button onClick={onClose} className={s.closeWalletBtn}>
          <VscChromeClose />
        </button>
        <div className={s.steps} data-noclose>
          <div className={activeClasses(s, 1)} data-noclose>
            <span data-noclose>{t("main.addWallet.addWalletStep1")}</span>
          </div>
        </div>
        {activeStep === 1 ? (
          <AddWalletContent
            walletAdress={walletAdress}
            setWalletAdress={setWalletAdress}
            t={t}
            nextStep={setActiveStep}
          />
        ) : !error?.length ? (
          <div className={s.succesVerify} data-noclose>
            <img src={DoneCircle} data-noclose alt="circle" />
            <h2 data-noclose>{t("main.verifySucces")}</h2>
            <span data-noclose>{t("main.addWallet.walletAdded")}</span>
            <button
              onClick={() => {
                onClose();
                dispatch(getWallets(1, alert, t));
              }}
            >
              {t("main.addWallet.onDashboard")}
            </button>
          </div>
        ) : (
          <div className={s.succesVerify} data-noclose>
            <img data-noclose src={ErrorCircle} alt="circle" />
            <h2 data-noclose>{t("main.verifyFail")}</h2>
            <span data-noclose>{t("main.addWallet.registrationFail")}</span>
            <button
              onClick={() => {
                onClose();
                dispatch(getWallets(1, alert, t));
              }}
            >
              {t("main.addWallet.onDashboard")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddWalletBtc;
