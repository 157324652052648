import { Route, Routes } from "react-router-dom";
import LoginPage from "./components/LoginPage/LoginPage";
import Registration from "./components/Registration/Registration";
import Dashboard from "./components/Dashboard/Dashboard";
import Main from "./components/Main/Main";
import Header from "./components/Header/Header";
import CabinetEnter from "./components/CabinetEnter/CabinetEnter";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import { RequireAuth } from "./hoc/RequireAuth";
import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from "react-toastify";
import React, { useState } from "react";
import "./App.css";
import { Alert } from "./components/Alert/Alert";
import Settings from "./components/SettingsPage/Settings";
import History from "./components/History/History";
import { Analytics } from "./components/Analytics/Analytics";
import { ReplenishmentHistory } from "./components/ReplenishmentHistory/ReplenishmentHistory";

if (typeof window !== "undefined") {
  injectStyle();
}

export const AlertContext = React.createContext();

function App() {
  const [openMobileTotal, setOpenMobileTotal] = useState(false);
  const [openMenu, setOpenMenu] = useState(window.innerWidth > 700);

  return (
    <div className="App">
      <AlertContext.Provider value={Alert}>
        <Header
          openMobileTotal={openMobileTotal}
          setOpenMobileTotal={setOpenMobileTotal}
        />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<Registration />} />
          <Route path="/signup/auth" element={<CabinetEnter />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                <Dashboard
                  setOpenMobileTotal={setOpenMobileTotal}
                  open={openMenu}
                  setOpen={setOpenMenu}
                />
              </RequireAuth>
            }
          >
            <Route
              path="dashboard"
              element={
                <RequireAuth>
                  <Main openMenu={openMenu} />
                </RequireAuth>
              }
            />
            <Route
              path="analytics"
              element={
                <RequireAuth>
                  <Analytics openMenu={openMenu} />
                </RequireAuth>
              }
            />
            <Route
              path="history"
              element={
                <RequireAuth>
                  <History openMenu={openMenu} />
                </RequireAuth>
              }
            >
              <Route
                path=":page"
                element={
                  <RequireAuth>
                    <History openMenu={openMenu} />
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="replenishments"
              element={
                <RequireAuth>
                  <ReplenishmentHistory openMenu={openMenu} />
                </RequireAuth>
              }
            >
              <Route
                path=":page"
                element={
                  <RequireAuth>
                    <ReplenishmentHistory openMenu={openMenu} />
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="settings"
              element={
                <RequireAuth>
                  <Settings openMenu={openMenu} />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
        <ToastContainer />
      </AlertContext.Provider>
    </div>
  );
}

export default App;
