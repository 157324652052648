import { useEffect, useState } from "react";
import { socket } from "..";

export const useSocket = () => {
  const [updated, setIsUpdated] = useState(false);
  const [repIdUpdate, setRepIdUpdate] = useState(null);

  useEffect(() => {
    if (!socket?.connected) {
      socket.connect();
    }
    socket.on("getRatesUpdateStatus", (isUpdated) => {
      if (isUpdated) {
        setIsUpdated(isUpdated);
      }
    });
    socket.on("replenishmentUpdated", (updatedRep) => {
      if (updatedRep?.id) {
        setRepIdUpdate(updatedRep);
      }
    });
  }, []);

  return { updated, setIsUpdated, repIdUpdate, setRepIdUpdate };
};
