import { useEffect } from "react";
import { useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import i18n from "../../utils/i18next";
import s from "./LanguageSelector.module.css";

const LanguageSelector = ({ setLang, lang }) => {
  const [openLang, setOpenLang] = useState(false);
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLang(lang);
    setOpenLang(!openLang);
  };

  useEffect(() => {
  }, []);

  return (
    <div className={s.changeLangWrapper}>
      <div className={s.changeLang} onClick={() => setOpenLang(!openLang)}>
        <span>{lang.toUpperCase()}</span>
        {openLang ? (
          <MdOutlineArrowBackIosNew style={{ transform: "rotate(90deg)" }} />
        ) : (
          <MdOutlineArrowBackIosNew style={{ transform: "rotate(270deg)" }} />
        )}
      </div>
      {openLang ? (
        <div
          className={s.select}
          onClick={() => changeLanguage(lang === "ru" ? "en" : "ru")}
        >
          <span>{lang === "ru" ? "EN" : "RU"}</span>
        </div>
      ) : null}
    </div>
  );
};

export default LanguageSelector;
