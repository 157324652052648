import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GET_HISTORY } from "../../redux/sagas/historySaga";
import NoData from "../../assets/Component10.png";
import s from "./History.module.css";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router-dom";
import { setHistoryValue } from "../../redux/reducers/historyReducer";
import { VscChromeClose } from "react-icons/vsc";
import { useState } from "react";
import { AlertContext } from "../../App";
import { HistoryItem } from "./HistoryItem/HistoryItem";

const History = ({ openMenu }) => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const dispatch = useDispatch();
  let history = useNavigate();
  let location = useParams();
  const pageData = useSelector((state) => state.history.data?.data);
  const meta = useSelector((state) => state.history.data?.meta);

  const [openErrorPopup, setOpenErrorPopup] = useState(false);

  const handlePageClick = (event) => {
    dispatch({ type: GET_HISTORY, page: event.selected + 1, alert, t });
    history(`/history/${event.selected + 1}`);
  };

  useEffect(() => {
    if (location.page) {
      dispatch({ type: GET_HISTORY, page: location.page, alert, t });
    } else {
      dispatch({ type: GET_HISTORY, page: 1, history, alert, t });
    }
    return () => {
      dispatch(setHistoryValue({}));
    };
  }, []);

  useEffect(() => {
    if (pageData?.length && meta?.current_page === 1) {
      history(`/history/${meta?.current_page}`);
    }
  }, [pageData]);

  return (
    <>
      {pageData?.length ? (
        <div className={`${s.wrapper} ${openMenu ? s.wrapperFull : null}`}>
          <h2>{t("history.history")}</h2>
          <div className={s.table}>
            <div className={s.header}>
              <div>{t("history.data")}</div>
              <div>{t("history.token")}</div>
              <div>{t("history.pi")}</div>
              <div>{t("history.hc")}</div>
              <div>{t("history.i")}</div>
              <div>{t("history.status")}</div>
            </div>
            {pageData
              ? pageData?.map((el, idx) => (
                  <HistoryItem
                    el={el}
                    key={`${el?.id}${idx}`}
                    openErrorPopup={openErrorPopup}
                    setOpenErrorPopup={setOpenErrorPopup}
                  />
                ))
              : null}

            {openErrorPopup ? (
              <div
                className={s.errorPopupWrapper}
                onClick={(e) => {
                  if (
                    e.target.nodeName === "DIV" &&
                    !e.target.attributes.hasOwnProperty("data-noclose")
                  ) {
                    setOpenErrorPopup(false);
                  }
                }}
              >
                <div data-noclose className={s.errorPopup}>
                  <div
                    onClick={() => setOpenErrorPopup(false)}
                    className={s.closeBtn}
                  >
                    <VscChromeClose />
                  </div>
                  {t("history.error")}
                </div>
              </div>
            ) : null}

            {meta?.last_page > 1 ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel={t("history.nextPage")}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={meta?.last_page}
                previousLabel={t("history.prevPage")}
                className={s.pages}
                pageClassName={s.pageNum}
                activeClassName={s.selected}
                previousLinkClassName={s.step}
                nextLinkClassName={s.step}
                breakClassName={s.break}
                forcePage={meta?.current_page - 1}
                renderOnZeroPageCount={null}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <>
          <div className={`${s.wrapper} ${openMenu ? s.wrapperFull : null}`}>
            <h2>{t("history.history")}</h2>
          </div>
          <div className={`${s.noData} ${openMenu ? s.noDataFull : null}`}>
            <img src={NoData} alt="no data" />
            <h4>{t("history.noDataTitle")}</h4>
            <span>{t("history.nodata")}</span>
          </div>
        </>
      )}
    </>
  );
};

export default History;

