import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DoneCircle from "../../assets/tick-circle.png";
import s from "./Main.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getMoreWallets,
  getWallets,
} from "../../redux/reducers/addWalletReducer";
import Loader from "../Loader/Loader";
import { AlertContext } from "../../App";
import AddWalletCone from "./components/AddWalletCone/AddWalletCone";
import AddWalletBtc from "./components/AddWalletBtc/AddWalletBtc";
import Wallet from "./components/Wallet/Wallet";
import { AddWalletButton } from "./components/AddWalletButton/AddWalletButton";
import {
  GET_CURRENCIES_TO_PAY,
  setBuyConePopup,
} from "../../redux/reducers/buyConeReducer";
import { BuyConePopup } from "./components/BuyConePopup/BuyConePopup";

const Main = ({ openMenu }) => {
  const dispatch = useDispatch();

  const [openAddWallet, setOpenAddWallet] = useState({
    btc: false,
    cone: false,
  });
  const [openedPopup, setOpenedPopup] = useState(false);
  const [btcWallet, setBtcWallet] = useState(null);
  const [coneWallets, setConeWallets] = useState([]);

  const isBuyConePopup = useSelector((state) => state?.buyCurrency?.isPopup);
  const walletsData = useSelector((state) => state?.addWallet?.wallets);
  const loading = useSelector((state) => state?.addWallet?.loading);

  const { meta } = walletsData;

  const { t } = useTranslation();
  const alert = useContext(AlertContext);

  useEffect(() => {
    dispatch(getWallets(1, alert, t));
    dispatch({ type: GET_CURRENCIES_TO_PAY, t, alert });
  }, []);

  useEffect(() => {
    setOpenedPopup(openAddWallet.btc || openAddWallet.cone);
  }, [openAddWallet]);

  useEffect(() => {
    if (walletsData.data?.length) {
      setBtcWallet(walletsData.data.find((el) => el.currency === "BTC"));
      setConeWallets(walletsData.data.filter((el) => el.currency !== "BTC"));
    } else {
      setBtcWallet(null);
      setConeWallets([]);
    }
  }, [walletsData]);

  const newUserCheck = () => {
    if (localStorage.getItem("newEmail") === localStorage.getItem("e")) {
      return (
        <div className={s.walletContent}>
          <div
            className={`${s.regSucces} ${openMenu ? s.regSuccesFull : null}`}
          >
            <img src={DoneCircle} alt="circle" />
            <span>{t("main.registrationSuccess")}</span>
          </div>
        </div>
      );
    } else if (!localStorage.getItem("newEmail") && !walletsData.data?.length) {
      return <></>;
    }
  };

  return (
    <>
      <div className={`${s.wrapper} ${openMenu ? s.wrapperFull : null}`}>
        <div className={s.walletHeader}>
          <h2>{t("main.title")}</h2>
        </div>
        <div
          className={`${s.allWalletsWrapper} ${
            openedPopup ? s.walletsFixed : ""
          }`}
        >
          {!!btcWallet ? (
            <Wallet
              key={btcWallet.id}
              data={btcWallet}
              openBuyModal={() =>
                dispatch(setBuyConePopup({ isPopup: true, walletId: "" }))
              }
            />
          ) : (
            <AddWalletButton
              currency="btc"
              disabled={
                loading ||
                walletsData.data?.filter((el) => el.currency === "BTC").length
              }
              clickFunction={() =>
                setOpenAddWallet((state) => {
                  return { ...state, btc: true };
                })
              }
            />
          )}

          <AddWalletButton
            currency="cone"
            clickFunction={() =>
              setOpenAddWallet((state) => {
                return { ...state, cone: true };
              })
            }
          />
          {coneWallets?.length
            ? coneWallets.map((el) => (
                <Wallet
                  key={el.id}
                  data={el}
                  openBuyModal={() =>
                    dispatch(
                      setBuyConePopup({ isPopup: true, walletId: el.id })
                    )
                  }
                />
              ))
            : null}
        </div>
        {openAddWallet.cone ? (
          <AddWalletCone
            onClose={() =>
              setOpenAddWallet((state) => {
                return { ...state, cone: false };
              })
            }
          />
        ) : null}

        {openAddWallet.btc ? (
          <AddWalletBtc
            onClose={() =>
              setOpenAddWallet((state) => {
                return { ...state, btc: false };
              })
            }
          />
        ) : null}

        {isBuyConePopup ? <BuyConePopup /> : null}

        {walletsData?.meta?.current_page < walletsData?.meta?.last_page ? (
          <div
            onClick={() =>
              dispatch(
                getMoreWallets(walletsData?.meta?.current_page + 1, alert, t)
              )
            }
            className={`${s.more} ${openedPopup ? s.moreFixed : ""}`}
          >
            {t("main.more")}
          </div>
        ) : null}
        {loading ? <Loader /> : null}
      </div>

      {!walletsData.data?.length ? newUserCheck() : null}
    </>
  );
};

export default Main;
