import { takeEvery, put, call } from "redux-saga/effects";
import { api, getError } from "../../api/api";
import {
  CREATE_REPLENISHMENT,
  DELETE_REPLENISHMENT,
  GET_CONE_WALLETS,
  GET_CURRENCIES_TO_PAY,
  GET_EXCHANGE_RATE,
  UPDATE_REPLENISHMENT,
  setConeWallets,
  setCurrenciesToPay,
  setExchangeRate,
  setReplenishment,
  setBuyConePopup,
  setLoading,
  setInitialBuyCone,
} from "../reducers/buyConeReducer";
import { getHashrate, getWallets } from "../reducers/addWalletReducer";
import { availableCurrencies } from "../../utils/constanst";
import { setIsBuyAvailable } from "../reducers/settingsReducer";

function* deleteReplenishmentWorker({ replenishmentInput, setStep, alert, t }) {
  try {
    const data = yield call(api.deleteReplenishment, replenishmentInput);
    if (data.status === 200) {
      yield put(setInitialBuyCone());
      yield put({ type: GET_CURRENCIES_TO_PAY, t, alert });
      setStep();
      alert("success", t("buyConePopup.successReplenishmentDelete"));
    }
  } catch (error) {
    console.log(error);
    if (getError(error).data.errors[0].message.includes("not found")) {
      alert("error", t("errors.replenishmentNotFound"));
    }
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return;
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else console.log(getError(error).data.errors);
  }
}

function* createReplenishmentWorker({
  replenishmentInput,
  coneValues,
  alert,
  t,
}) {
  try {
    yield put(setLoading(true));
    const data = yield call(api.createReplenishment, replenishmentInput);
    if (data.status === 200) {
      const dataToSet = data?.data;
      yield put(setReplenishment(dataToSet || 0));
    }
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    console.log(error);
    if (
      getError(error).data.errors[0].message.includes("Minimum purchase amount")
    ) {
      alert("error", `${t("errors.minimumConeAmount")} ${coneValues?.minCone}`);
      yield put({
        type: GET_EXCHANGE_RATE,
        currency: replenishmentInput.currency,
        amount: coneValues?.minCone,
        alert,
        t,
        minCone: coneValues?.minCone,
      });
    }
    if (getError(error).data.errors[0].message.includes("already exist")) {
      alert("error", t("errors.replenishmentAlreadyExist"));
    }
    if (getError(error).data.errors[0].message.includes("not found")) {
      alert("error", t("errors.replenishmentNotFound"));
    }
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return;
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else console.log(getError(error).data.errors);
  }
}

function* getReplenishmentWorker({ id, alert, t }) {
  try {
    const data = yield call(api.getReplenishmentToWatch, id);
    if (data.status === 200) {
      const dataToSet = data?.data;
      yield put(setReplenishment(dataToSet || 0));
    }
    if (data.status === 204) {
      yield put(getHashrate());
      yield put(getWallets(1, alert, t));
    }
  } catch (error) {
    console.log(error);
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return;
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else console.log(getError(error).data.errors);
  }
}

function* getExchangeRateWorker({ currency, amount, alert, t, minCone = 100 }) {
  try {
    yield put(setLoading(true));
    const data = yield call(api.calculateExchange, {
      currency,
      amount,
    });
    if (data.status === 200) {
      const dataToSet = {
        rate: data?.data.cone_rate,
        total: data?.data.cone_amount,
        fee: {
          usd: data?.data.fee_in_usd,
          currency: data?.data.fee_in_currency,
        },
        inputs: {
          currency: data?.data.currency_amount,
          cone: data?.data.cone_amount,
        },
      };
      yield put(setExchangeRate(dataToSet || null));
      yield put(setLoading(false));
    }
  } catch (error) {
    //REWRITE
    if (
      getError(error).data.errors[0].message.includes("Minimum purchase amount")
    ) {
      yield put(setLoading(false));
      alert("error", `${t("errors.minimumConeAmount")} ${minCone}`);
      const data = yield call(api.calculateExchange, {
        currency,
        amount: minCone,
      });
      if (data.status === 200) {
        const dataToSet = {
          rate: data?.data.cone_rate,
          total: data?.data.cone_amount,
          fee: {
            usd: data?.data.fee_in_usd,
            currency: data?.data.fee_in_currency,
          },
          inputs: {
            currency: data?.data.currency_amount,
            cone: data?.data.cone_amount,
          },
        };
        yield put(setExchangeRate(dataToSet || null));
        yield put(setLoading(false));
      }
      //
      return;
    }
    yield put(setLoading(false));
    console.log(error);
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return;
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else console.log(getError(error).data.errors);
  }
}

function* getCurrenciesToPayWorker({ t, alert }) {
  try {
    const data = yield call(api.getExchangeCurrencies);
    if (data.status === 200) {
      const mappedCurrencies = data?.data.map((el) => {
        return {
          ...el,
          name: el.name || "",
          value: el.value || el.networks[0]?.value || "",
          decimalLimit: el.decimal || el.networks[0]?.decimal || 8,
          minCone:
            el["min_cone_amount"] || el?.networks[0]["min_cone_amount"] || 100,
        };
      });
      const dataToSet = availableCurrencies
        .filter((el) => {
          if (
            !!mappedCurrencies.find((item) => {
              return item.value === el.value;
            })
          ) {
            return true;
          } else {
            return false;
          }
        })
        .map((el) => {
          const item = mappedCurrencies.find((item) => {
            return item.value === el.value;
          });
          return {
            ...el,
            decimalLimit: item.decimalLimit || 8,
            minCone: `${item.minCone}` || 100,
          };
        });

      if (dataToSet.length) {
        yield put(setIsBuyAvailable(true));
      } else {
        yield put(setIsBuyAvailable(false));
      }

      yield put(setCurrenciesToPay(dataToSet));
    } else {
      yield put(setBuyConePopup({ isPopup: false }));
      alert("error", t("networkError"));
      return;
    }
  } catch (error) {
    console.log(error);
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return;
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else console.log(getError(error).data.errors);
  }
}

function* getConeWalletsWorker({ t, alert }) {
  try {
    const data = yield call(api.getConeWallets);
    if (data.status === 200) {
      const dataToSet = data?.data.map((el) => {
        return {
          name: el.address || "",
          value: el.id || "",
        };
      });
      yield put(setConeWallets(dataToSet));
    } else {
      yield put(setBuyConePopup({ isPopup: false }));
      alert("error", t("buyConeError"));
      return;
    }
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return;
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else console.log(getError(error).data.errors);
  }
}

export function* buyCurrencyWatcher() {
  yield takeEvery(GET_EXCHANGE_RATE, getExchangeRateWorker);
  yield takeEvery(GET_CONE_WALLETS, getConeWalletsWorker);
  yield takeEvery(GET_CURRENCIES_TO_PAY, getCurrenciesToPayWorker);
  yield takeEvery(CREATE_REPLENISHMENT, createReplenishmentWorker);
  yield takeEvery(UPDATE_REPLENISHMENT, getReplenishmentWorker);
  yield takeEvery(DELETE_REPLENISHMENT, deleteReplenishmentWorker);
}
