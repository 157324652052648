import { useEffect } from "react";

const UseClickOutside = (elementRef, handler, attached = true) => {
  useEffect(() => {
    if (!attached) return;
    const handleClick = (e) => {
      if (!elementRef.current) return;
      if (
        typeof e?.target?.value === "string" ||
        !elementRef?.current?.contains(e?.target?.value)
      ) {
        handler();
      } else {
        handler();
      }
    };
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [elementRef, handler, attached]);
};

export { UseClickOutside };
