import { useEffect, useRef, useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import s from "./Selector.module.css";
import { UseClickOutside } from "../../../../../../../hooks/UseClickOutside";

export const Selector = ({
  items,
  onChange,
  selectedItem,
  zIndex = "",
  boldName = false,
  fullName = false,
  disabled = false,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const selectWrapRef = useRef(null);
  UseClickOutside(
    selectWrapRef,
    () => {
      setIsOpened(false);
    },
    isOpened
  );
  return (
    <div
      ref={selectWrapRef}
      data-noclose
      className={disabled ? `${s.wrapper} ${s.disabled}` : `${s.wrapper}`}
    >
      <div
        data-noclose
        onClick={!disabled ? () => setIsOpened(!isOpened) : null}
        className={s.selectedItem}
      >
        {selectedItem?.icon ? (
          <img
            data-noclose
            className={s.currencyImg}
            src={selectedItem.icon}
            alt={selectedItem.name}
          />
        ) : null}
        <div data-noclose className={s.nameHandler}>
          <span data-noclose style={boldName ? { fontWeight: "bold" } : {}}>
            {selectedItem?.name}
            {fullName ? (
              <span data-noclose className={s.fullName}>
                {selectedItem?.fullName}
              </span>
            ) : null}
          </span>
          {selectedItem?.network ? (
            <span data-noclose className={s.network}>
              {selectedItem?.network}
            </span>
          ) : null}
        </div>

        <MdOutlineArrowBackIosNew
          data-noclose
          style={
            isOpened
              ? {
                  marginLeft: "10px",
                  transform: "rotate(90deg)",
                  color: "#BEBDBD",
                }
              : {
                  marginLeft: "10px",
                  transform: "rotate(270deg)",
                  color: "#BEBDBD",
                }
          }
        />
      </div>
      <div
        data-noclose
        style={isOpened ? { zIndex } : { zIndex, display: "none" }}
        className={s.dropdown}
      >
        {items?.length
          ? items.map((el, ind) => {
              return (
                <div
                  data-noclose
                  key={ind + el.value}
                  onClick={
                    !disabled
                      ? () => {
                          onChange(el);
                          setIsOpened(!isOpened);
                        }
                      : null
                  }
                  className={s.dropdownItem}
                >
                  {el.icon ? (
                    <img
                      className={s.currencyImg}
                      src={el.icon}
                      alt={el.name}
                    />
                  ) : null}
                  <div data-noclose className={s.nameHandler}>
                    <span data-noclose className={s.itemName}>
                      {el.name}
                    </span>
                    {el.network ? (
                      <span data-noclose className={s.network}>
                        {el?.network}
                      </span>
                    ) : null}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};
