import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addError, logOut } from "../../redux/reducers/signUpReducer";
import RegistrationForm from "./components/RegistartionForm/RegistrationForm";
import LanguageSelector from "../../common-components/LanguageSelector/LanguageSelector";
import s from "./Registration.module.css";

const Registration = () => {
  const currentLang = localStorage.getItem("i18nextLng");

  const [lang, setLang] = useState(currentLang);
  const [resizeSmall, setResizeSmall] = useState(true);
  const [resizeBig, setResizeBig] = useState(false);

  const userEmail = useSelector((state) => state.signUp.signUp.email);
  const isVerify = useSelector((state) => state.signUp.signUp.verifyed);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  let history = useNavigate();

  useEffect(() => {
    const listenResizeEvent = () => {
      window.innerWidth <= 700 ? setResizeSmall(true) : setResizeSmall(false);
      window.innerWidth > 700 ? setResizeBig(true) : setResizeBig(false);
    };
    listenResizeEvent();
    window.addEventListener("resize", listenResizeEvent, { passive: true });
    return () => window.removeEventListener("resize", listenResizeEvent);
  }, []);

  useEffect(() => {
    if (userEmail) {
      dispatch(logOut());
    }
    return () => dispatch(addError(null));
  }, []);

  useEffect(() => {
    if (userEmail && !isVerify) {
      history("/signup/auth");
    }
  }, [userEmail, isVerify]);

  return (
    <>
      <div className={s.wrapper}>
        <div className={s.signup}>
          <div className={s.signupForm}>
            <LanguageSelector setLang={setLang} lang={lang} />
            <div className={s.title}>
              <span>{t("registration.title")}</span>
            </div>
            <RegistrationForm resizeSmall={resizeSmall} resizeBig={resizeBig} />
            <div className={s.loginWrapper}>
              <span className={s.noProfile}>
                {t("registration.haveProfile")}
              </span>
              <Link to="/login" className={s.loginLink}>
                <span>{t("registration.login")}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
