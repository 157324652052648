export const GET_SETTING_VALUES = "GET_SETTING_VALUES";
export const SET_SETTING_VALUES = "SET_SETTING_VALUES";
export const SET_ERRORS = "SET_ERRORS";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_DASHBOARD_NAV = "SET_DASHBOARD_NAV";
export const SET_IS_AVAILABLE = "SET_IS_AVAILABLE";

const initialState = {
  lang: "en",
  twoFactorVerify: "false",
  errors: null,
  isDashboardNav: true,
  isBuyAvailable: false,
};

export default function addWalletReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SETTING_VALUES:
      return {
        ...state,
        lang: action.payload.lang,
        twoFactorVerify: action.payload.tfa,
      };
    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        lang: action.payload,
      };
    case SET_DASHBOARD_NAV:
      return {
        ...state,
        isDashboardNav: action.payload,
      };
    case SET_IS_AVAILABLE:
      return {
        ...state,
        isBuyAvailable: action.payload,
      };
    default:
      return state;
  }
}

export const setWalletValue = (payload) => ({
  type: SET_SETTING_VALUES,
  payload,
});

export const setErrors = (payload) => ({
  type: SET_ERRORS,
  payload,
});

export const setLanguage = (payload) => ({
  type: SET_LANGUAGE,
  payload,
});

export const setDashboardNav = (payload) => ({
  type: SET_DASHBOARD_NAV,
  payload,
});

export const setIsBuyAvailable = (payload) => ({
  type: SET_IS_AVAILABLE,
  payload,
});
