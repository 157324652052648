import { takeEvery, put, call } from "redux-saga/effects";
import { api, getError } from "../../api/api";
import {
  setHistoryValue,
  setReplenishmentDetailsPopup,
  setReplenishmentToWatch,
  setReplenishmentsValue,
} from "../reducers/historyReducer";

export const GET_HISTORY = "GET_HISTORY";
export const GET_REPLENISHMENTS_HISTORY = "GET_REPLENISHMENTS_HISTORY";

function* replenishmentsDataWorker({ page = 1, alert, t }) {
  try {
    const data = yield call(api.getReplenishmentsHistory, page);
    if (data.status === 200) {
      yield put(setReplenishmentsValue(data?.data));
    }
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return;
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else console.log(getError(error).data.errors);
    // yield put(setErrors(getError(error).data.errors));
  }
}

function* historyDataWorker({ page = 1, alert, t }) {
  try {
    const data = yield call(api.getPaymentHistory, page);
    if (data.status === 200) {
      yield put(setHistoryValue(data?.data));
    }
  } catch (error) {
    if (getError(error).status === 0) {
      alert("error", t("networkError"));
      return;
    }
    if (getError(error).status === 401) {
      document.cookie = "BIT/bearer=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    } else console.log(getError(error).data.errors);
    // yield put(setErrors(getError(error).data.errors));
  }
}

export function* historyWatcher() {
  yield takeEvery(GET_HISTORY, historyDataWorker);
  yield takeEvery(GET_REPLENISHMENTS_HISTORY, replenishmentsDataWorker);
}
