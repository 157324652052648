export const SET_HISTORY_VALUES = "SET_HISTORY_VALUES";
export const SET_REPLENISHMENTS_VALUES = "SET_REPLENISHMENTS_VALUES";
export const SET_REPLENISHMENT_DETAILS = "SET_REPLENISHMENT_DETAILS";


const initialState = {
  isReplenishment: false,
  data: [],
  replenishmentsData: [],
};

export default function historyReducer(state = initialState, action) {
  switch (action.type) {
    case SET_HISTORY_VALUES:
      return {
        ...state,
        data: action.payload,
      };
    case SET_REPLENISHMENTS_VALUES:
      return {
        ...state,
        replenishmentsData: action.payload,
      };
    case SET_REPLENISHMENT_DETAILS:
      return {
        ...state,
        isReplenishment: action.payload,
      };
    default:
      return state;
  }
}

export const setHistoryValue = (payload) => ({
  type: SET_HISTORY_VALUES,
  payload,
});

export const setReplenishmentsValue = (payload) => ({
  type: SET_REPLENISHMENTS_VALUES,
  payload,
});

export const setReplenishmentDetailsPopup = (payload) => ({
  type: SET_REPLENISHMENT_DETAILS,
  payload,
});
