import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AlertContext } from "../../../../App";
import ErrorImg from "../../../../assets/err.png";
import { setResetPassEmail } from "../../../../redux/reducers/signUpReducer";
import { RESEND_VERIFY_CODE } from "../../../../redux/sagas/signUpSaga";
import s from "../../Registration.module.css";

const ErrorsBlock = ({ errors, email }) => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);

  const dispatch = useDispatch();
  let alreadyExist = errors?.some(
    (el) => el.message === "Email already exists"
  );

  return (
    <div className={s.errorWrapper}>
      <img src={ErrorImg} alt={"error_img"} />
      <div className={s.errorContent}>
        <span className={s.errorTitle}>{t("errors.signup")}</span>
        {alreadyExist ? (
          <span>
            {t("errors.emailExists")}
            <Link to={"/login"}>{t("errors.in")}</Link>
            {t("errors.or")}
            <Link
              to={"/resetPassword"}
              onClick={() => {
                dispatch(setResetPassEmail(email.toLocaleLowerCase()));
                dispatch({
                  type: RESEND_VERIFY_CODE,
                  email: email.toLocaleLowerCase(),
                  lang: localStorage.getItem("i18nextLng"),
                  alert,
                  t,
                });
              }}
            >
              {t("errors.changePass")}
            </Link>
          </span>
        ) : (
          <span>{t("errors.check")}</span>
        )}
      </div>
    </div>
  );
};

export default ErrorsBlock;
